import {Component, OnInit} from '@angular/core';
import {BaseResponse} from "../../../../models/BaseResponse";
import {Major} from "../../../../models/Major";
import {Post} from "../../../../models/Post";
import {faArrowLeft, faArrowRight, faCalendar, faSearch, faUser} from '@fortawesome/free-solid-svg-icons';
import {DataRepositoryImpl} from "../../../../domain/data.repositoryimpl";
import {ActivatedRoute} from "@angular/router";
import {MenuDataResponse} from "../../../../models/MenuDataResponse";
import {Tag} from "../../../../models/Tag";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-post-list-body',
  templateUrl: './post-list-body.component.html',
  styleUrls: ['./post-list-body.component.css']
})
export class PostListBodyComponent implements OnInit {
  loading: boolean = false;
  posts: Post[];
  tags: Tag[];
  recent_posts: Post[];
  host = environment.apiUrl;
  item_count = 0;
  total_page: number = 0;
  page_size = 20;
  pages: number[];
  pageActive = 1;
  pageFilter = '';
  faArrowRight = faArrowRight
  faArrowLeft = faArrowLeft
  faCalendar = faCalendar
  faSearch = faSearch
  faUser = faUser
  majors: Major[];
  pageParams = '';
  tag = '';
  searchText = '';

  constructor(private repository: DataRepositoryImpl, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['page'] != null && !isNaN(this.route.snapshot.queryParams['page'])) {
      this.pageActive = Number(this.route.snapshot.queryParams['page']);
    }

    if (this.route.snapshot.queryParams['search'] != null) {
      this.pageParams += '&search=' + this.route.snapshot.queryParams['search'];
      this.searchText = this.route.snapshot.queryParams['search'];
    }

    if (this.route.snapshot.queryParams['tag'] != null) {
      this.pageParams += '&tag=' + this.route.snapshot.queryParams['tag'];
      this.tag = this.route.snapshot.queryParams['tag'];
    }

    console.log("pageActive: " + this.pageActive)

    this.getPosts()
    this.getRightMenuData();
  }

  public getPosts() {
    console.log(this.pageFilter)
    if (isNaN(this.pageActive)) {
      this.pageActive = 1;
    }
    this.repository.getPosts(this.pageActive - 1, this.page_size, this.tag, this.searchText).subscribe((data: BaseResponse<Post[]>) => {
      this.posts = data.data;
      this.total_page = (data.item_count / this.page_size) | 0;
      this.total_page += 1;
      console.log("total_page: " + this.total_page);
      console.log("data_size: ", data.data.length)
      this.pages = Array.from({length: this.total_page}, (v, k) => k + 1);
      console.log("pages: " + this.pages);
      this.loading = false;
    });
  }

  public getDelayTime(i: number) {
    if (i % 2 == 0) {
      return '0.1s'
    } else {
      return '0.6s'
    }
  }

  public getRightMenuData() {
    this.repository.getRightMenuData().subscribe((data: BaseResponse<MenuDataResponse>) => {
      this.recent_posts = data.data.posts;
      this.tags = data.data.tags;
    });
  }

  public searchPost() {
    if (this.searchText != '') {
      window.location.href = "posts?search=" + this.searchText
    }else{
      window.location.href = "/posts/"
    }
  }

  public changeTextSearch(event) {
    this.searchText = event.target.value;
  }

  public enterSearch(event) {
    this.searchText = event.target.value;
    this.searchPost();
  }
}
