import {Component, OnInit} from '@angular/core';
import {TestDetail} from "../../../models/TestDetail";
import {ActivatedRoute} from "@angular/router";
import {DataRepositoryImpl} from "../../../domain/data.repositoryimpl";
import {BaseResponse} from "../../../models/BaseResponse";
import {Meta, Title} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-listening-detail',
  templateUrl: './listening-detail.component.html',
  styleUrls: ['./listening-detail.component.css']
})
export class ListeningDetailComponent implements OnInit {
  testDetail: TestDetail;
  answerNumber: string[] = [];


  constructor(private route: ActivatedRoute, private repository: DataRepositoryImpl, private titleService: Title, private meta: Meta) {
    // environment.app_name+' - ' + 'test abc';
  }

  ngOnInit(): void {
    if (this.testDetail == null) {
      this.getReadingDetail();
    }
  }

  getReadingDetail() {
    let alias = String(this.route.snapshot.paramMap.get('title'));
    this.repository.getTestByName(alias).subscribe((data: BaseResponse<TestDetail>) => {
      // console.log("Test response");
      if (data.data != null) {
        this.testDetail = data.data;
        let localTestId = 'test_' + this.testDetail.topic_test.id;
        console.log("localTestId: " + localTestId);
        localStorage.removeItem(localTestId);
        this.buildMetaTags();
        for (let i = 0; i < this.testDetail.questions.length; i++) {
          let result = this.testDetail.questions[i].result
          let answers = result.split(',')
          for (let a = 0; a < answers.length; a++) {
            this.answerNumber.push('question' + this.testDetail.questions[i].id + "_answer" + (a + 1))
          }
        }
        this.titleService.setTitle(environment.app_name + ' - ' + this.testDetail.topic_test.title);
      } else {
        window.location.href = '/page-not-fount';
      }
    }, () => {
      window.location.href = '/page-not-fount';
    });

  }

  private buildMetaTags() {
    this.meta.addTag({name: 'description', content: this.testDetail.topic_test.seo_content});
    this.meta.addTag({
      name: 'keywords',
      content: 'English language, IELTS, English proficiency, Language skills, Speaking, Listening, Reading, Writing, Vocabulary, Grammar, Communication, Fluency, Academic English, Test preparation, Language proficiency, IELTS exam, Band score, Speaking test, Listening test, Reading test, Writing test, IELTS speaking tips, IELTS listening tips, IELTS reading tips, IELTS writing tips, IELTS study materials, Practice tests, Test format, Time management, IELTS score, IELTS band descriptors, Academic module, General Training module, IELTS registration, IELTS results, Test center, IELTS speaking topics, IELTS writing task 1, IELTS writing task 2, IELTS reading passages'
    })
    this.meta.addTag({name: 'title', content: 'IELTS BUILDER - ' + this.testDetail.topic_test.title})
    this.meta.addTag({name: 'robots', content: 'index, follow'})
    this.meta.addTag({name: 'googlebot', content: 'index, follow'})
    this.meta.addTag({property: 'og:type', content: 'Listening detail page'})
    this.meta.addTag({
      property: 'og:url',
      content: 'https://ieltsbuilder.com/listening/detail/' + this.testDetail.topic_test.alias
    })
    this.meta.addTag({property: 'og:title', content: 'IELTS BUILDER - ' + this.testDetail.topic_test.title})
    this.meta.addTag({property: 'og:site_name', content: 'IELTS BUILDER'})
    this.meta.addTag({property: 'og:description', content: this.testDetail.topic_test.seo_content})
    this.meta.addTag({name: 'author', content: 'IELTS BUILDER'})
  }
}
