import { Component } from '@angular/core';
import {Meta} from "@angular/platform-browser";

@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.css']
})
export class PodcastComponent {
  constructor(private meta: Meta) {
    this.buildMetaTags();
  }

  private buildMetaTags(){
    this.meta.addTag({name: 'description', content: "IELTS BUILDER - Collection of ielts podcast with various of topic"});
    this.meta.addTag({name: 'keywords', content: 'English language, IELTS, English proficiency, Language skills, Speaking, Listening, Reading, Writing, Vocabulary, Grammar, Communication, Fluency, Academic English, Test preparation, Language proficiency, IELTS exam, Band score, Speaking test, Listening test, Reading test, Writing test, IELTS speaking tips, IELTS listening tips, IELTS reading tips, IELTS writing tips, IELTS study materials, Practice tests, Test format, Time management, IELTS score, IELTS band descriptors, Academic module, General Training module, IELTS registration, IELTS results, Test center, IELTS speaking topics, IELTS writing task 1, IELTS writing task 2, IELTS reading passages'})
    this.meta.addTag({name: 'title', content: 'IELTS BUILDER - Posts'})
    this.meta.addTag({name: 'robots', content: 'index, follow'})
    this.meta.addTag({name: 'googlebot', content: 'index, follow'})
    this.meta.addTag({property: 'og:type', content: 'Podcast page'})
    this.meta.addTag({property: 'og:url', content: 'https://ieltsbuilder.com/podcast'})
    this.meta.addTag({property: 'og:title', content: 'IELTS BUILDER - Posts'})
    this.meta.addTag({property: 'og:site_name', content: 'IELTS BUILDER'})
    this.meta.addTag({property: 'og:description', content: 'IELTS BUILDER - Collection of ielts podcast with various of topic'})
    this.meta.addTag({name: 'author', content: 'IELTS BUILDER'})
  }
}
