<app-main-nav-bar></app-main-nav-bar>
<app-main-search></app-main-search>
<section class="grammar-detail-body container">
  <h2 *ngIf="grammar!=null" style="margin-top: 24px">
    {{grammar.title}}
  </h2>
  <img *ngIf="grammar!=null" class="test-image" alt="{{grammar.title}}"
       src="{{host+'api/static?image='+ grammar.image}}">

  <div style="margin-top: 24px">
        <span style="text-align: justify" *ngIf="grammar!=null"
              [innerHTML]="grammar.description"></span>
  </div>
</section>

<section class="more-grammar">
  <div class="container py-5" *ngIf="grammars.length!=0">
    <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
      <h5 class="fw-bold text-primary text-uppercase"><a href="/grammar">More Lesson</a></h5>
    </div>
    <div class="row g-3 flex-wrap">
      <ng-template ngFor let-itemReading [ngForOf]="grammars" let-i="index">
        <div class="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div
            class="service-item bg-light rounded d-flex flex-column align-items-center text-center">
            <div class="service-icon">
              <a style="width: 100%; height: 100%" href="/grammar/detail/{{itemReading.alias}}">
                <img alt="{{itemReading.title}}" style="width: 100%; height: 100%;object-fit: cover;"
                     src="{{host +'api/static?image='+ itemReading.image}}"></a>
            </div>
            <h5 class="m-2"><a href="/grammar/detail/{{itemReading.alias}}">{{itemReading.title}}</a></h5>
            <h6 *ngIf="itemReading.book!=null" class="mb-3"><a
              href="/grammar?book={{itemReading.book.alias}}">{{itemReading.book.title}}</a></h6>
            <h6 *ngIf="itemReading.book==null" class="mb-3"><a href="/grammar">General</a></h6>
            <a class="btn btn-lg btn-primary rounded" href="/grammar/detail/{{itemReading.alias}}">
              <fa-icon [icon]="faArrowRight"></fa-icon>
            </a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</section>
<app-main-footer></app-main-footer>
