<app-main-header></app-main-header>
<app-main-top-bar></app-main-top-bar>
<app-main-nav-bar></app-main-nav-bar>
<app-main-search></app-main-search>
<!--<asw-google-adsense-->
<!--  [adClient]="'ca-pub-8356666175852878'"-->
<!--  [adSlot]="6964492589"-->
<!--  [adFormat]="'auto'"-->
<!--  [fullWidthResponsive] = "true"-->
<!--  [display]="'inline-block'"-->
<!--&gt;</asw-google-adsense>-->
<!--<ins class="adsbygoogle"-->
<!--     style="display:block"-->
<!--     data-ad-client="ca-pub-8356666175852878"-->
<!--     data-ad-slot="6964492589"-->
<!--     data-ad-format="auto"-->
<!--     data-full-width-responsive="true"></ins>-->
<div class="container">
  <ins #ins class="adsbygoogle"
       style="display:block"
       data-adtest="on"
       data-ad-client="ca-pub-8356666175852878"
       data-ad-slot="2573095084"
       data-ad-format="auto"
       data-full-width-responsive="true"></ins>
</div>
<app-reading-body-list></app-reading-body-list>
<app-main-footer></app-main-footer>
