import {Component, OnInit} from '@angular/core';
import {TestDetail} from "../../../../models/TestDetail";
import {ActivatedRoute} from "@angular/router";
import {DataRepositoryImpl} from "../../../../domain/data.repositoryimpl";
import {BaseResponse} from "../../../../models/BaseResponse";
import {QuestionAnswer} from "../../../../models/QuestionAnswer";

@Component({
  selector: 'app-preview-readimg-result',
  templateUrl: './preview-readimg-result.component.html',
  styleUrls: ['./preview-readimg-result.component.css']
})
export class PreviewReadimgResultComponent implements OnInit {
  testDetail: TestDetail;
  answerNumber: string[] = [];
  answerTable: QuestionAnswer[] = [];

  constructor(private route: ActivatedRoute, private repository: DataRepositoryImpl) {
  }

  ngOnInit(): void {
    if (this.testDetail == null) {
      this.getReadingDetail();
    }
  }

  getReadingDetail() {
    let alias = String(this.route.snapshot.paramMap.get('title'));

    this.repository.getTestByName(alias).subscribe((data: BaseResponse<TestDetail>) => {
      this.testDetail = data.data;
      // this.answerTable = new Map();
      let answerNumber: string[] = [];
      let answerTable: QuestionAnswer[] = [];
      for (let i = 0; i < this.testDetail.questions.length; i++) {
        let question = this.testDetail.questions[i];
        let result = question.result
        let answers = result.split(',')
        for (let a = 0; a < answers.length; a++) {
          let answerKey = 'question' + this.testDetail.questions[i].id + "_answer" + (a + 1)
          answerNumber.push(answerKey);
          // answerTable.push(answerKey, answers[a]);
          let questionAnswer = new QuestionAnswer();
          questionAnswer.id = question.id;
          questionAnswer.actual_answer = answers[a].trim();
          questionAnswer.option = String(a + 1);
          questionAnswer.answer_type = question.answerType;
          questionAnswer.user_answer = '';
          answerTable.push(questionAnswer);
        }
      }

      this.answerNumber = answerNumber;
      this.answerTable = answerTable;
      // this.buildUserAnswerPreviewData();
    }, () => {
      window.location.href = '/page-not-fount';
    });
  }

  private buildUserAnswerPreviewData() {
    if (this.testDetail != null) {
      let json = localStorage.getItem('test_' + this.testDetail.topic_test.id);
      if (json != null) {
        let studentAnswers = new Map(JSON.parse(json));
        for (let i = 0; i < this.answerTable.length; i++) {
          //"#question9_answer2"
          let answer_value = '';
          if (this.answerTable[i].answer_type != 2) {
            let user_answer_key = "#question" + this.answerTable[i].id + "_answer" + (this.answerTable[i].option)
            console.log("user_answer_key: "+user_answer_key);
            if (studentAnswers.has(user_answer_key)) {
              answer_value = String(studentAnswers.get(user_answer_key));
            }
            this.answerTable[i].user_answer = answer_value;
          }else{
            let keys = Array.from( studentAnswers.keys() );
            let countItems = 0;
            let countActualAnswer =0;
            for (let k =0; k<keys.length;k++) {
              //"#question11_answer5"
              let key_check = "#question"+this.answerTable[i].id
              console.log("key_check: "+key_check);
              if(String(keys[k]).includes(key_check)){
                countActualAnswer++;
                if(this.answerTable[i].actual_answer.trim().toLowerCase() == String(studentAnswers.get(keys[k])).trim().toLowerCase()){
                  answer_value = String(studentAnswers.get(keys[k])).trim();
                  this.answerTable[i].user_answer = answer_value;
                }else{
                  countItems++;
                }
              }
            }

            if(countItems == countActualAnswer){
              this.answerTable[i].user_answer = '-1';
            }
          }
        }

        console.log(this.answerTable);
      }
    }
  }
}
