<app-main-header></app-main-header>
<app-main-top-bar></app-main-top-bar>
<app-main-nav-bar></app-main-nav-bar>
<app-main-search></app-main-search>

<!--<ins class="adsbygoogle"-->
<!--     style="display:block"-->
<!--     data-ad-client="ca-pub-8356666175852878"-->
<!--     data-ad-slot="2573095084"-->
<!--     data-ad-format="auto"-->
<!--     data-full-width-responsive="true"></ins>-->

<!--<asw-google-adsense-->
<!--  [adClient]="'ca-pub-8356666175852878'"-->
<!--  [adSlot]="2573095084"-->
<!--  [display]="'inline-block'"-->
<!--  [fullWidthResponsive] = "true"-->
<!--  [adFormat]="'auto'"-->
<!--&gt;</asw-google-adsense>-->
<!--<div class="container">-->
<!--  <ins #ins class="adsbygoogle"-->
<!--       style="display:block"-->
<!--       data-adtest="on"-->
<!--       data-ad-client="ca-pub-8356666175852878"-->
<!--       data-ad-slot="2573095084"-->
<!--       data-ad-format="auto"-->
<!--       data-full-width-responsive="true"></ins>-->
<!--</div>-->
<app-listening-body-list></app-listening-body-list>
<app-main-footer></app-main-footer>
