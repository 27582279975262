import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {Meta} from "@angular/platform-browser";

@Component({
  selector: 'app-grammar',
  templateUrl: './grammar.component.html',
  styleUrls: ['./grammar.component.css']
})
export class GrammarComponent {
  title = environment.app_name+' - ' + this.route.snapshot.data["title"];

  constructor(private route: ActivatedRoute, private meta: Meta) {
    this.buildMetaTags()
  }

  private buildMetaTags(){
    this.meta.addTag({name: 'description', content: 'Grammar list. Grammar have revolutionized the way we consume written content. With their convenience and accessibility, eBooks have become increasingly popular among readers worldwide. To enhance the reader\'s experience and provide easy navigation within an eBook, the inclusion of a listing page has become a common practice.'});
    this.meta.addTag({name: 'keywords', content: 'English language, IELTS, English proficiency, Language skills, Speaking, Listening, Reading, Writing, Vocabulary, Grammar, Communication, Fluency, Academic English, Test preparation, Language proficiency, IELTS exam, Band score, Speaking test, Listening test, Reading test, Writing test, IELTS speaking tips, IELTS listening tips, IELTS reading tips, IELTS writing tips, IELTS study materials, Practice tests, Test format, Time management, IELTS score, IELTS band descriptors, Academic module, General Training module, IELTS registration, IELTS results, Test center, IELTS speaking topics, IELTS writing task 1, IELTS writing task 2, IELTS reading passages'})
    this.meta.addTag({name: 'title', content: 'Grammar page'})
    this.meta.addTag({name: 'robots', content: 'index, follow'})
    this.meta.addTag({name: 'googlebot', content: 'index, follow'})
    this.meta.addTag({property: 'og:type', content: 'Grammar page'})
    this.meta.addTag({property: 'og:url', content: 'https://ieltsbuilder.com/grammar'})
    this.meta.addTag({property: 'og:title', content: 'Grammar pages'})
    this.meta.addTag({property: 'og:site_name', content: 'IELTS BUILDER'})
    this.meta.addTag({property: 'og:description', content: 'Grammar Page list. Grammar have revolutionized the way we consume written content. With their convenience and accessibility, eBooks have become increasingly popular among readers worldwide. To enhance the reader\'s experience and provide easy navigation within an eBook, the inclusion of a listing page has become a common practice.'})
    this.meta.addTag({name: 'author', content: 'IELTS BUILDER'})
  }
}
