<!-- Features Start -->
<!--<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">-->
<div class="container py-1">
  <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
    <h5 class="fw-bold text-primary text-uppercase"><a href="/reading">New Listening</a></h5>
    <h1 class="mb-0">We Are Here to Grow Your English Listening Skills Exponentially</h1>
  </div>
  <div class="row g-3">
    <ng-template ngFor let-itemReading [ngForOf]="listening" let-i="index">
      <div class="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.3s">
        <div
          class="service-item bg-light rounded d-flex flex-column align-items-center text-center">
          <div class="service-icon">
            <a style="width: 100%; height: 100%" href="/listening/detail/{{itemReading.alias}}">
              <img alt="{{itemReading.title}}" style="width: 100%; height: 100%;object-fit: cover;"
                   src="{{host +'api/static?image='+ itemReading.image}}">
            </a>
          </div>
          <h5 class="mb-3 ms-2 me-2">{{itemReading.title}}</h5>
          <h6 *ngIf="itemReading.major!=null" class="mb-3"><a
            href="/listening?major={{itemReading.major.alias}}">{{itemReading.major.title}}</a></h6>
          <h6 *ngIf="itemReading.major==null" class="mb-3"><a href="/listening">General</a></h6>
          <a class="btn btn-lg btn-primary rounded" href="/listening/detail/{{itemReading.alias}}">
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </a>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<!--</div>-->

<!-- Service Start -->
<!--<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">-->
<div class="container py-5">
  <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
    <h5 class="fw-bold text-primary text-uppercase"><a href="/reading">New Reading</a></h5>
    <h1 class="mb-0">Accelerate Reading Comprehension</h1>
  </div>
  <div class="row g-3">
    <ng-template ngFor let-itemReading [ngForOf]="readings" let-i="index">
      <div class="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.3s">
        <div
          class="service-item bg-light rounded d-flex flex-column align-items-center text-center">
          <div class="service-icon">
            <a style="width: 100%; height: 100%" href="/reading/detail/{{itemReading.alias}}">
              <img alt="{{itemReading.title}}" style="width: 100%; height:100%;object-fit: cover;"
                   src="{{host +'api/static?image='+ itemReading.image}}">
            </a>
          </div>
          <h5 class="p-2">{{itemReading.title}}</h5>
          <h6 *ngIf="itemReading.major!=null" class="mb-3"><a
            href="/reading?major={{itemReading.major.alias}}">{{itemReading.major.title}}</a></h6>
          <h6 *ngIf="itemReading.major==null" class="mb-3"><a href="/reading">General</a></h6>
          <a class="btn btn-lg btn-primary rounded" href="/reading/detail/{{itemReading.alias}}">
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </a>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!-- Blog Start -->
<!--<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">-->
<div class="container py-5">
  <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
    <h5 class="fw-bold text-primary text-uppercase">Latest Blog</h5>
    <h1 class="mb-0">Read The Latest Articles from Our Blog Post</h1>
  </div>
  <div class="row g-4">
    <ng-template ngFor let-post [ngForOf]="posts" let-i="index">
      <div class="col-lg-4 wow slideInUp" data-wow-delay="0.01s">
        <div class="blog-item rounded overflow-hidden bg-light">
          <div class="blog-img position-relative overflow-hidden">
            <img class="img-fluid" src="{{host +'api/static?image='+ post.image}}" alt="">
            <!--            <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Web-->
            <!--              Design</a>-->
          </div>
          <div class="blog-description p-4">
            <div class="d-flex mb-3">
              <small class="me-3"><i class="far fa-user text-primary me-2"></i>Admin</small>
              <small><i class="far fa-calendar-alt text-primary me-2"></i>{{post.createdDate | date: 'dd MMM, yyyy'}}
              </small>
            </div>
            <h4 class="mb-3">{{post.title}}</h4>
            <p class="short-description">{{post.short_description}}</p>
            <a class="read-more" href="/posts/detail/{{post.alias}}">Read More
              <fa-icon [icon]="faArrowRight"></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<!--</div>-->
<!-- Blog Start -->

<!-- Team Start -->
<!--<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">-->
<!--  <div class="container py-5">-->
<!--    <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">-->
<!--      <h5 class="fw-bold text-primary text-uppercase">Team Members</h5>-->
<!--      <h1 class="mb-0">Professional Stuffs Ready to Help Your Business</h1>-->
<!--    </div>-->
<!--    <div class="row g-5">-->
<!--      <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">-->
<!--        <div class="team-item bg-light rounded overflow-hidden">-->
<!--          <div class="team-img position-relative overflow-hidden">-->
<!--            <img class="img-fluid w-100" src="assets/img/team-1.jpg" alt="">-->
<!--            <div class="team-social">-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href=""><i-->
<!--                class="fab fa-twitter fw-normal"></i></a>-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href=""><i-->
<!--                class="fab fa-facebook-f fw-normal"></i></a>-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href=""><i class="fab fa-instagram fw-normal"></i></a>-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href="">-->
<!--                <fa-icon [icon]="faLinked"></fa-icon>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="text-center py-4">-->
<!--            <h4 class="text-primary">Full Name</h4>-->
<!--            <p class="text-uppercase m-0">Designation</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">-->
<!--        <div class="team-item bg-light rounded overflow-hidden">-->
<!--          <div class="team-img position-relative overflow-hidden">-->
<!--            <img class="img-fluid w-100" src="assets/img/team-2.jpg" alt="">-->
<!--            <div class="team-social">-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href="">-->
<!--                <fa-icon [icon]="faTwitter"></fa-icon>-->
<!--              </a>-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href=""><i-->
<!--                class="fab fa-facebook-f fw-normal"></i></a>-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href=""><i class="fab fa-instagram fw-normal"></i></a>-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href=""><i-->
<!--                class="fab fa-linkedin-in fw-normal"></i></a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="text-center py-4">-->
<!--            <h4 class="text-primary">Full Name</h4>-->
<!--            <p class="text-uppercase m-0">Designation</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">-->
<!--        <div class="team-item bg-light rounded overflow-hidden">-->
<!--          <div class="team-img position-relative overflow-hidden">-->
<!--            <img class="img-fluid w-100" src="assets/img/team-3.jpg" alt="">-->
<!--            <div class="team-social">-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href=""><i-->
<!--                class="fab fa-twitter fw-normal"></i></a>-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href=""><i-->
<!--                class="fab fa-facebook-f fw-normal"></i></a>-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href=""><i class="fab fa-instagram fw-normal"></i></a>-->
<!--              <a class="btn btn-lg btn-primary btn-lg-square rounded" href=""><i-->
<!--                class="fab fa-linkedin-in fw-normal"></i></a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="text-center py-4">-->
<!--            <h4 class="text-primary">Full Name</h4>-->
<!--            <p class="text-uppercase m-0">Designation</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!-- Team End -->

