<!-- Footer Start -->
    <div class="container-fluid bg-dark text-light wow fadeInUp" data-wow-delay="0.5s">
        <div class="container">
            <div class="row gx-5" >
<!--                <div class="col-lg-4 col-md-6 footer-about">-->
<!--                    <div class="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">-->
<!--                        <a href="index.html" class="navbar-brand">-->
<!--                            <h1 class="m-0 text-white"><i class="fa fa-user-tie me-2"></i>{{app_name}}</h1>-->
<!--                        </a>-->
<!--                        <p class="mt-3 mb-4">Signing up for {{app_name}} is free and allows you to easily keep track of all your lessons and also speeds up the process of ordering new videos. Simply enter your email address and choose a password below to get started.</p>-->
<!--                        <form action="">-->
<!--                            <div class="input-group">-->
<!--                                <input type="text" class="form-control border-white p-3" placeholder="Your Email">-->
<!--                                <button class="btn btn-dark">Sign Up</button>-->
<!--                            </div>-->
<!--                        </form>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="col-lg-8 col-md-6 center">
                    <div class="row gx-5">
                        <div class="col-lg-4 col-md-12 pt-5 mb-5">
                            <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 class="text-light mb-0">Get In Touch</h3>
                            </div>
<!--                            <div class="d-flex mb-2">-->
<!--                                <i class="bi bi-geo-alt text-primary me-2"></i>-->
<!--                                <p class="mb-0">123 Street, New York, USA</p>-->
<!--                            </div>-->
                            <div class="d-flex mb-2">
                                <i class="bi bi-envelope-open text-primary me-2"></i>
                                <p class="mb-0">{{emai_contact}}</p>
                            </div>
<!--                            <div class="d-flex mb-2">-->
<!--                                <i class="bi bi-telephone text-primary me-2"></i>-->
<!--                                <p class="mb-0">+012 345 67890</p>-->
<!--                            </div>-->
<!--                            <div class="d-flex mt-4">-->
<!--                                <a class="btn btn-primary btn-square me-2" href="#"><i class="fab fa-twitter fw-normal"></i></a>-->
<!--                                <a class="btn btn-primary btn-square me-2" href="#"><i class="fab fa-facebook-f fw-normal"></i></a>-->
<!--                                <a class="btn btn-primary btn-square me-2" href="#"><i class="fab fa-linkedin-in fw-normal"></i></a>-->
<!--                                <a class="btn btn-primary btn-square" href="#"><i class="fab fa-instagram fw-normal"></i></a>-->
<!--                            </div>-->
                        </div>
                        <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 class="text-light mb-0">Quick Links</h3>
                            </div>
                            <div class="link-animated d-flex flex-column justify-content-start">
                                <a class="text-light mb-2" href="/"><i class="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                <a class="text-light mb-2" href="/listening"><i class="bi bi-arrow-right text-primary me-2"></i>Listening</a>
                                <a class="text-light mb-2" href="/reading"><i class="bi bi-arrow-right text-primary me-2"></i>Reading</a>
                                <a class="text-light mb-2" href="/podcast"><i class="bi bi-arrow-right text-primary me-2"></i>Podcast</a>
<!--                                <a class="text-light mb-2" href="/resources"><i class="bi bi-arrow-right text-primary me-2"></i>Resources</a>-->
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                            <div class="section-title section-title-sm position-relative pb-3 mb-4">
                                <h3 class="text-light mb-0">Popular Links</h3>
                            </div>
                            <div class="link-animated d-flex flex-column justify-content-start">
                                <a class="text-light mb-2" href="/"><i class="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                <a class="text-light mb-2" href="/ebooks"><i class="bi bi-arrow-right text-primary me-2"></i>Ebooks</a>
                              <a class="text-light mb-2" href="/grammar"><i class="bi bi-arrow-right text-primary me-2"></i>Grammar</a>
<!--                                <a class="text-light mb-2" href="/out-feature"><i class="bi bi-arrow-right text-primary me-2"></i>Our Features</a>-->
<!--                                <a class="text-light mb-2" href="/team-member"><i class="bi bi-arrow-right text-primary me-2"></i>Meet The Team</a>-->
                                <a class="text-light mb-2" href="/podcast"><i class="bi bi-arrow-right text-primary me-2"></i>Podcast</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid text-white" style="background: #061429;">
        <div class="container text-center">
            <div class="row justify-content-end">
<!--                <div class="col-lg-8 col-md-6">-->
                    <div class="d-flex align-items-center justify-content-center" style="height: 75px;">
                        <p class="mb-0">&copy; <a class="text-white border-bottom" href="#">{{app_name}}</a>. All Rights Reserved.

						<!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
						Designed by <a class="text-white border-bottom" href="/">{{app_name}}</a></p>
                    </div>
<!--                </div>-->
            </div>
        </div>
    </div>
    <!-- Footer End -->


    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><fa-icon [icon]="faArrowUp"></fa-icon> </a>
