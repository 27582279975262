import { Component } from '@angular/core';
import {faUser, faCheck, faAward} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-main-face-start',
  templateUrl: './main-face-start.component.html',
  styleUrls: ['./main-face-start.component.css']
})
export class MainFaceStartComponent {
  faUser = faUser;
  faCheck = faCheck;
  faAward = faAward;
}
