import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DataRepositoryImpl} from "../../../../domain/data.repositoryimpl";
import {Contact} from "../../../../models/Contact";
import {BaseResponse} from "../../../../models/BaseResponse";
import {OnExecuteData, ReCaptchaV3Service} from "ng-recaptcha";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  testForm: FormGroup;
  submitted = false;

  // public executeImportantAction(): void {
  //   this.recaptchaV3Service.execute('importantAction')
  //     .subscribe((token) => this.handleToken(token));
  // }

  // private handleToken(token: string) {
  //   console.log("recapcha_token: " + token)
  // }

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private formBuilder: FormBuilder,
    private repository: DataRepositoryImpl) {
  }

  handleToken(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    console.log(this.testForm.value);
    this.repository.createContact(this.testForm.value, captchaResponse).subscribe((data: BaseResponse<Contact>) => {
      // this.toastr.success("Create test successful", "Message", {
      //   timeOut: 3000,
      //   easeTime: 0,
      //   positionClass: 'toast-bottom-right'
      // })
      alert('Your contact was sent successful. We will contact you soon as possible');
      this.onReset()
    });
  }


  onSubmit() {
    // TODO: Use EventEmitter with form value
    this.submitted = true;
    if (this.testForm.invalid) {
      return;
    }else{
     this.recaptchaV3Service.execute('sendMessage')
      .subscribe((token) => this.handleToken(token));
    }
  }

  onReset() {
    this.testForm?.reset();
    this.submitted = false;
  }

  ngOnInit(): void {
    this.testForm = this.formBuilder.group({
      full_name: ['', Validators.required],
      email: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });

    this.subscription = this.recaptchaV3Service.onExecute.subscribe((data: OnExecuteData) => {
      this.handleRecaptchaExecute(data.action, data.token);
    });
  }

  get f() {
    return this.testForm.controls;
  }

  private handleRecaptchaExecute(action: string, token: string) {
    console.log("action: "+action)
    console.log("token: "+token)
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
