import {
  AfterContentChecked,
  AfterViewChecked, ChangeDetectorRef, Component,
  ElementRef,
  Input,
  OnInit
} from '@angular/core';
import {TestDetail} from "../../../../models/TestDetail";
import {Question} from "../../../../models/Question";
import {AnswerType} from "../../../../utils/AnswerType";
import {Router} from "@angular/router";
import {QuestionAnswer} from "../../../../models/QuestionAnswer";
import {faCheckCircle, faCircleXmark, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {TopicTest} from "../../../../models/TopicTest";
import {BaseResponse} from "../../../../models/BaseResponse";
import {DataRepositoryImpl} from "../../../../domain/data.repositoryimpl";
import {environment} from "../../../../../environments/environment";

declare var $: any;

@Component({
  selector: 'app-listening-detail-body',
  templateUrl: './listening-detail-body.component.html',
  styleUrls: ['./listening-detail-body.component.css']
})
export class ListeningDetailBodyComponent implements AfterViewChecked, OnInit, AfterContentChecked {

  faArrowRight = faArrowRight
  @Input()
  testDetail: TestDetail;
  @Input()
  answerNumber: string[];
  @Input()
  is_show_preview: boolean = false;

  @Input()
  answerTable: QuestionAnswer[];
  answerTableModified: QuestionAnswer[];

  listenings: TopicTest[] = [];
  questionViews: string[] | null = null;

  studentAnswers: Map<any, any> = new Map();
  answerPreview = new Map();

  host = environment.apiUrl
  faCircleXmark = faCircleXmark;
  faCheckCircle = faCheckCircle;

  totalCorrect = 0;
  totalIncorrect = 0;
  totalUnanswered = 0;
  timeTaken = '00:00';
  bandScore = 0;

  cols = false;
  cells = {
    a: 'bottom',
    a1: 'right',
    a2: 'right',
    b: 'none',
    b1: 'right',
    b2: 'none'
  };

  constructor(private elementRef: ElementRef, private router: Router, private dataRepository: DataRepositoryImpl,
              private cdref: ChangeDetectorRef) {

  }

  ngAfterContentChecked(): void {
    if (this.is_show_preview) {
      if (this.testDetail != null && this.studentAnswers.size == 0) {
        let json = localStorage.getItem('test_' + this.testDetail.topic_test.id);
        this.timeTaken = String(localStorage.getItem('q_taken_' + this.testDetail.topic_test.id));
        if (json != null) {
          this.studentAnswers = new Map(JSON.parse(json));
        }

        this.answerTableModified = this.answerTable;
      }
    }

    if (this.questionViews == null && this.testDetail != null) {
      this.questionViews = [];
      for (let i = 0; i < this.testDetail.questions.length; i++) {
        let viewContent = this.buildQuestionContent(this.testDetail.questions[i])
        this.questionViews.push(viewContent)
      }
    }
    this.cdref.detectChanges();
  }

  afterLoad() {
    $(document).ready(function () {
      let divs = $('#iframe').contents().find('app-page-not-found').length
      if (divs == 1) {
        var htmlElement = document.getElementById("video_wrapper");
        if (htmlElement != null) {
          htmlElement.innerHTML = `<h4>Could not found audio source at the moment. Please try again later.</h4>`;
        }
      }
    })

  }

  ngOnInit(): void {
    this.startTimer();
    if (this.is_show_preview) {
      if (this.testDetail != null) {
        if (this.listenings.length == 0) {
          this.getREfListening();
        }
      }
    }
  }


  toggleDirection() {
    this.cols = !this.cols;
    if (this.cols) {
      this.cells.a = 'right';
      this.cells.a1 = 'bottom';
      this.cells.b1 = 'bottom';
    } else {
      this.cells.a = 'bottom';
      this.cells.a1 = 'right';
      this.cells.b1 = 'right';
    }
  }

  buildQuestionContent(question: Question) {
    try {
      if (question.answerType == AnswerType.TEXT) {
        return this.buildQuestionInputText(question);
      } else if (question.answerType == AnswerType.TRUE_FALSE_NG) {
        return this.buildTrueFalseNG(question)
      } else if (question.answerType == AnswerType.LIST_OF_HEADINGS) {
        return this.buildListOfHeading(question)
      } else if (question.answerType == AnswerType.MULTI_CHOICE) {
        return this.buildMultiChoice(question)
      } else if (question.answerType == AnswerType.SINGLE_CHOICE) {
        return this.buildSingleChoice(question)
      }
    } catch (e) {

    }
    return question.description;
  }

  private buildQuestionInputText(question: Question) {
    let questionId = question.id;
    let regex = /\{[0-9]?[0-99]\}/i;
    let regexName = /\{Option[0-9]?[0-99]\}/i;
    let description = question.description
    let allowReplace = true
    while (allowReplace) {
      let found = description.match(new RegExp(regex))
      if (found != null) {
        let foundName = String(description.match(new RegExp(regexName)))
        if (foundName != null) {
          foundName = String(foundName).replace("{", "").replace("}", "")
        }
        let number = String(found).replace("{", "").replace("}", "")

        let value_answer = '';
        try {
          let index = Number(foundName.replace("Option", "").trim())
          let question_answer = "#question" + questionId + "_answer" + index;
          if (this.studentAnswers.has(question_answer)) {
            value_answer = this.studentAnswers.get(question_answer);
          }
        } catch (e) {

        }
        let checkdisable = '';
        let actual_result = '';
        if (this.is_show_preview) {
          checkdisable = 'disabled'
          let index = Number(foundName.replace("Option", "").trim())
          if (index > 0) {
            index = index - 1;
          } else {
            index = 0;
          }
          if (index < this.answerTableModified.length) {
            this.insertQuestionAnswer(questionId, index, value_answer);
          }
          let answers = question.result.split(',');
          try {
            actual_result = 'Key: ' + answers[index].trim();
          } catch (e) {

          }
        }

        let index = Number(foundName.replace("Option", "").trim())
        let editText = `<b class="number-format m-2">` + number + `</b><input ` + checkdisable + ` value="` + value_answer + `" name="` + foundName + `" id="answer_` + questionId + `_` + index + `" maxlength="30" type="text" class="answer-text"/><span style="color: #b02a37; font-weight: bold; margin-left: 4px">` + actual_result + `</span>`
        if (!this.is_show_preview) {
          editText = `<b class="number-format m-2">` + number + `</b><input name="` + foundName + `" id="answer_` + questionId + `_` + (index) + `" maxlength="30" type="text" class="answer-text"/>`
        }
        description = description.replace(regex, editText);
        description = description.replace(regexName, '');
        allowReplace = true
      } else {
        allowReplace = false
      }
    }
    this.summaryBandScore();
    return description;
  }

  answer: '';

  answerText(event) {
    // alert(event);
    let input = event.target.value;

    let inputArray = event.target.id.split('_');
    let number = inputArray[2];
    let questionId = inputArray[1];
    let numSelectedView = '#question' + questionId + '_' + 'answer' + number;

    if (event.target.id.includes("radio") == true) {
      input = inputArray[3];
    } else if (event.target.type == 'checkbox') {
      let keys = [...this.studentAnswers.keys()];
      let countAnswer = 0;
      for (let i = 0; i < keys.length; i++) {
        if (this.studentAnswers.get(keys[i]) != null && this.studentAnswers.get(keys[i]) != '') {
          if (keys[i].includes('question' + questionId)) {
            countAnswer++;
          }
        }
      }
      //find total answer of question
      let totalAnswerOfQuestion = 0;
      for (let i = 0; i < this.testDetail.questions.length; i++) {
        if (this.testDetail.questions[i].id == questionId) {
          try {
            totalAnswerOfQuestion = this.testDetail.questions[i].result.split(",").length
          } catch (e) {
          }
        }
      }

      if (event.target.checked == true) {
        input = inputArray[3];
        if (countAnswer == totalAnswerOfQuestion) {
          event.target.checked = false;
        } else {
          this.studentAnswers.set(numSelectedView, input);
          let keys = [...this.studentAnswers.keys()];

          // dem so cau answer hop le va to mau cho no
          let answerValidCount = 0;
          for (let j = 0; j < keys.length; j++) {
            if (keys[j].includes('#question' + questionId)) {
              if (this.studentAnswers.get(keys[j]) != null) {
                answerValidCount++;
              }
            }
          }
          for (let i = 0; i < answerValidCount; i++) {
            numSelectedView = '#question' + questionId + '_' + 'answer' + (i + 1);
            if (this.elementRef.nativeElement.querySelector(numSelectedView)) {
              this.elementRef.nativeElement.querySelector(numSelectedView).style.backgroundColor = '#06A3DA'
              this.elementRef.nativeElement.querySelector(numSelectedView).style.color = 'white'
            }
          }
        }
      } else {
        for (let i = 0; i < keys.length; i++) {
          if (this.studentAnswers.get(keys[i]) == inputArray[3]) {
            if (keys[i].includes('#question' + questionId)) {
              // remove answer item
              this.studentAnswers.set(keys[i], null);
              break;
            }
          }
        }

        let answerValidCount = 0;
        for (let j = 0; j < keys.length; j++) {
          if (keys[j].includes('#question' + questionId)) {
            if (this.studentAnswers.get(keys[j]) != null) {
              answerValidCount++;
            }
          }
        }

        for (let i = 0; i < totalAnswerOfQuestion; i++) {
          numSelectedView = '#question' + questionId + '_' + 'answer' + (i + 1);
          if (i < answerValidCount) {
            if (this.elementRef.nativeElement.querySelector(numSelectedView)) {
              this.elementRef.nativeElement.querySelector(numSelectedView).style.backgroundColor = '#06A3DA'
              this.elementRef.nativeElement.querySelector(numSelectedView).style.color = 'white'
            }
          } else {
            if (this.elementRef.nativeElement.querySelector(numSelectedView)) {
              this.elementRef.nativeElement.querySelector(numSelectedView).style.backgroundColor = '#fff'
              this.elementRef.nativeElement.querySelector(numSelectedView).style.color = '#6B6A75'
            }
          }

        }
      }

    }

    if (event.target.type == 'checkbox') {
    } else {
      this.studentAnswers.set(numSelectedView, input);
      if (input == null || input == '') {
        if (this.elementRef.nativeElement.querySelector(numSelectedView)) {
          this.elementRef.nativeElement.querySelector(numSelectedView).style.backgroundColor = '#fff'
          this.elementRef.nativeElement.querySelector(numSelectedView).style.color = '#6B6A75'
        }
      } else {
        if (this.elementRef.nativeElement.querySelector(numSelectedView)) {
          this.elementRef.nativeElement.querySelector(numSelectedView).style.backgroundColor = '#06A3DA'
          this.elementRef.nativeElement.querySelector(numSelectedView).style.color = 'white'
        }
      }
    }


  }

  private isSetEvent = false;

  ngAfterViewChecked(): void {
    if (!this.is_show_preview) {
      if (!this.isSetEvent) {
        if (this.testDetail != null) {
          this.isSetEvent = true
          for (let q = 0; q < this.testDetail.questions.length; q++) {
            let questionId = this.testDetail.questions[q].id;
            for (let i = 0; i < 50; i++) {
              let viewId = '#answer_' + questionId + '_' + i
              if (this.elementRef.nativeElement.querySelector(viewId)) {
                this.elementRef.nativeElement.querySelector(viewId).addEventListener('change', this.answerText.bind(this));
              }

              //check radio single choice
              let nameId = 'answer_' + questionId + '_' + i
              let nameFind = '[name="' + nameId + '"]';
              let items = this.elementRef.nativeElement.querySelectorAll(nameFind)
              if (items) {
                for (let j = 0; j < items.length; j++) {
                  items[j].addEventListener('change', this.answerText.bind(this));
                }
              }
            }
          }
        }
      }
    }
  }

  time: number = 0;
  display;
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
      this.display = this.transform(this.time)
    }, 1000);
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return ('00' + minutes).slice(-2) + ':' + ('00' + (value - minutes * 60)).slice(-2);
  }

  private buildTrueFalseNG(question: Question) {
    let checkdisabled = ''
    if (this.is_show_preview) {
      checkdisabled = 'disabled'
    }
    let regex = /\{[0-9]?[0-99]\}/i;
    let regexName = /\{Option[0-9]?[0-99]\}/i;
    let description = question.description
    let allowReplace = true
    while (allowReplace) {
      let found = description.match(new RegExp(regex))
      if (found != null) {
        let foundName = String(description.match(new RegExp(regexName)))
        if (foundName != null) {
          foundName = String(foundName).replace("{", "").replace("}", "")
        }
        let number = String(found).replace("{", "").replace("}", "")

        let value_answer = '';
        try {
          let question_answer = "#question" + question.id + "_answer" + foundName.replace("Option", "");
          if (this.studentAnswers.has(question_answer)) {
            value_answer = this.studentAnswers.get(question_answer);
          }
        } catch (e) {

        }
        let options = '';
        let actual_result = '';
        if (this.is_show_preview) {
          let answers = question.result.split(',');
          let index = Number(foundName.replace("Option", "").trim())
          if (index > 0) {
            index = index - 1
          } else {
            index = 0;
          }

          if (index < this.answerTableModified.length) {
            this.insertQuestionAnswer(question.id, index, value_answer);
          }

          try {
            actual_result = 'Key: ' + answers[index].trim()
          } catch (e) {

          }

          if (value_answer == '') {
            options = `<option value=""></option>
            <option value="TRUE">TRUE</option>
            <option value="FALSE">FALSE</option>
            <option value="NOT GIVEN">NOT GIVEN</option>`
          } else if (value_answer == 'TRUE') {
            options = `<option value=""></option>
            <option selected value="TRUE">TRUE</option>
            <option value="FALSE">FALSE</option>
            <option value="NOT GIVEN">NOT GIVEN</option>`
          } else if (value_answer == 'FALSE') {
            options = `<option value=""></option>
            <option value="TRUE">TRUE</option>
            <option selected value="FALSE">FALSE</option>
            <option value="NOT GIVEN">NOT GIVEN</option>`
          } else if (value_answer == 'NOT GIVEN') {
            options = `<option value=""></option>
            <option value="TRUE">TRUE</option>
            <option value="FALSE">FALSE</option>
            <option selected value="NOT GIVEN">NOT GIVEN</option>`
          }
        } else {
          options = `<option value=""></option>
            <option value="TRUE">TRUE</option>
            <option value="FALSE">FALSE</option>
            <option value="NOT GIVEN">NOT GIVEN</option>`
        }


        let selects = `<b style="margin-right: 8px">` + number + `</b>
          <select ` + checkdisabled + ` id="answer_` + question.id + `_` + foundName.replace("Option", "") + `"  name="` + foundName + `" style="margin-right: 8px; width: 120px">
          ` + options + `
          </select>
          <span style="color: #b02a37; font-weight: bold">` + actual_result + `</span>
          `
        description = description.replace(regex, selects);
        description = description.replace(regexName, '');
        allowReplace = true
      } else {
        allowReplace = false
      }
    }
    this.summaryBandScore();
    return description;
  }

  private buildListOfHeading(question: Question) {
    let checkdisabled = ''
    let checkselected = ''
    if (this.is_show_preview) {
      checkdisabled = 'disabled';
      checkselected = 'selected';
    }

    let headings: string[] = [];
    if (question.headings != null) {
      headings = question.headings.split(',');
    }

    let regexName = /\{Option[0-9]?[0-99]\}/i;
    let regexNumber = /\{[0-9]?[0-99]\}/i;
    let regexChar = /\{[A-Z]\}/i;
    let description = question.description
    let allowReplace = true
    while (allowReplace) {
      //case1: find number
      let found = description.match(new RegExp(regexNumber))
      if (found != null) {
        let number = String(found).replace("{", "").replace("}", "")
        let foundName = String(description.match(new RegExp(regexName)))
        if (foundName != null) {
          foundName = String(foundName).replace("{", "").replace("}", "")
        }

        let value_answer = '';
        try {
          let question_answer = "#question" + question.id + "_answer" + foundName.replace("Option", "");
          if (this.studentAnswers.has(question_answer)) {
            value_answer = this.studentAnswers.get(question_answer);
          }
        } catch (e) {

        }

        let options = ''
        for (let i = 0; i < headings.length; i++) {
          if (this.is_show_preview) {
            if (headings[i] == value_answer) {
              options = options + `<option ` + checkselected + ` value="` + headings[i] + `">` + headings[i] + `</option>`
            } else {
              options = options + `<option value="` + headings[i] + `">` + headings[i] + `</option>`
            }
          } else {
            options = options + `<option value="` + headings[i] + `">` + headings[i] + `</option>`
          }
        }

        let actual_result = '';
        if (this.is_show_preview) {
          let index = Number(foundName.replace("Option", "").trim())
          if (index > 0) {
            index = index - 1;
          } else {
            index = 0;
          }
          if (index < this.answerTableModified.length) {
            this.insertQuestionAnswer(question.id, index, value_answer);
          }

          let answers = question.result.split(',');
          try {
            actual_result = 'Key: ' + answers[index].trim();
          } catch (e) {

          }
        }

        let selects = `<b style="margin-right: 8px">` + number + `</b>
            <select ` + checkdisabled + ` id="answer_` + question.id + `_` + foundName.replace("Option", "") + `"  name="` + foundName + `" style="margin-right: 8px; width: 120px">
              <option value=""></option>
              ` + options +
          `</select> <span style="color: #b02a37; font-weight: bold; padding-right: 8px">` + actual_result + `</span>`
        description = description.replace(regexNumber, selects);
        description = description.replace(regexName, '');
        allowReplace = true
      } else {
        found = description.match(new RegExp(regexChar))
        if (found != null) {
          let foundName = String(description.match(new RegExp(regexName)))
          if (foundName != null) {
            foundName = String(foundName).replace("{", "").replace("}", "")
          }
          let number = String(found).replace("{", "").replace("}", "")

          let value_answer = '';
          try {
            let question_answer = "#question" + question.id + "_answer" + foundName.replace("Option", "");
            if (this.studentAnswers.has(question_answer)) {
              value_answer = this.studentAnswers.get(question_answer);
            }
          } catch (e) {

          }

          let options = ''
          for (let i = 0; i < headings.length; i++) {
            if (this.is_show_preview) {
              if (headings[i] == value_answer) {
                options = options + `<option ` + checkselected + ` value="` + headings[i] + `">` + headings[i] + `</option>`
              } else {
                options = options + `<option value="` + headings[i] + `">` + headings[i] + `</option>`
              }
            } else {
              options = options + `<option value="` + headings[i] + `">` + headings[i] + `</option>`
            }

          }

          let actual_result = '';
          if (this.is_show_preview) {
            let index = Number(foundName.replace("Option", "").trim())
            if (index > 0) {
              index = index - 1;
            }
            let answers = question.result.split(',');
            try {
              actual_result = 'Key: ' + answers[index].trim();
            } catch (e) {

            }

            if (index < this.answerTableModified.length) {
              this.insertQuestionAnswer(question.id, index, value_answer);
            }
          }

          let selects = `<b style="margin-right: 8px">` + number + `</b>
                <select ` + checkdisabled + ` id="answer_` + question.id + `_` + foundName.replace("Option", "") + `"  name="` + foundName + `" style="margin-right: 8px; width: 120px">
                  <option value=""></option>
                  ` + options +
            `</select> <span style="color: #b02a37; font-weight: bold; padding-right: 8px">` + actual_result + `</span>`

          description = description.replace(regexChar, selects);
          description = description.replace(regexName, '');
          allowReplace = true
        } else {
          allowReplace = false
        }
      }
    }

    //case2: find char
    this.summaryBandScore();
    return description;
  }

  private buildSingleChoice(question: Question) {
    let regexName = /\{Option[0-9]?[0-99]\}/i;
    let regexNumber = /\{[0-9]?[0-99]\}/i;
    let regexChar = /\{[A-Z]\}/i;
    let description = question.description
    let allowReplace = true
    while (allowReplace) {
      //case1: find number
      let found = description.match(new RegExp(regexNumber))

      if (found != null) {
        let foundName = String(description.match(new RegExp(regexName)))
        if (foundName != null) {
          foundName = String(foundName).replace("{", "").replace("}", "")
        }
        let actual_result = '';
        let answers = question.result.split(',');
        let number = String(found).replace("{", "").replace("}", "")

        let index = Number(foundName.replace("Option", "").trim())
        if (index > 0) {
          index = index - 1
        } else {
          index = 0;
        }

        try {
          if (answers[index].trim() == number) {
            actual_result = 'Key: ' + answers[index].trim();
          }
        } catch (e) {

        }

        let value_answer = '';

        let selects = `<b style="margin-right: 8px">` + number + `</b><input style="margin-right: 4px" type="radio" id="answer_` + question.id + `_` + foundName.replace("Option", "") + `"  name="answer_` + question.id + `_` + foundName.replace("Option", "") + `">`
        if (this.is_show_preview) {
          try {
            let question_answer = "#question" + question.id + "_answer" + foundName.replace("Option", "");
            if (this.studentAnswers.has(question_answer)) {
              value_answer = this.studentAnswers.get(question_answer);
            }

            if (index < this.answerTableModified.length) {
              this.insertQuestionAnswer(question.id, index, value_answer);
            }
          } catch (e) {

          }
          let check = '';
          if (value_answer == number) {
            check = 'checked';
          }

          selects = `<b style="margin-right: 8px">` + number + `</b><input style="margin-right: 4px" disabled ` + check + ` type="radio" id="answer_` + question.id + `_` + foundName.replace("Option", "") + `"  name="answer_` + question.id + `_` + foundName.replace("Option", "") + `">
            <span style="color: #b02a37; font-weight: bold; margin-right: 8px">` + actual_result + `</span>`
        }
        description = description.replace(regexNumber, selects);
        description = description.replace(regexName, '');

        allowReplace = true
      } else {
        //case2: find char
        found = description.match(new RegExp(regexChar))
        if (found != null) {
          let foundName = String(description.match(new RegExp(regexName)))
          if (foundName != null) {
            foundName = String(foundName).replace("{", "").replace("}", "")
          }

          let number = String(found).replace("{", "").replace("}", "")

          let value_answer = '';
          try {
            let question_answer = "#question" + question.id + "_answer" + foundName.replace("Option", "");

            if (this.studentAnswers.has(question_answer)) {
              value_answer = this.studentAnswers.get(question_answer);
            }
          } catch (e) {

          }

          let selects = `<b style="margin-right: 8px">` + number + `</b><input style="margin-right: 4px" type="radio" id="radio_` + question.id + `_` + foundName.replace("Option", "") + `_` + number + `"  name="answer_` + question.id + `_` + foundName.replace("Option", "") + `">`
          if (this.is_show_preview) {

            let check = '';
            if (value_answer == number) {
              check = 'checked';
            }
            try {
              let question_answer = "#question" + question.id + "_answer" + foundName.replace("Option", "");
              if (this.studentAnswers.has(question_answer)) {
                value_answer = this.studentAnswers.get(question_answer);
              }


            } catch (e) {

            }
            let actual_result = '';
            let answers = question.result.split(',');
            let index = Number(foundName.replace("Option", "").trim())
            if (index > 0) {
              index = index - 1
            }

            if (index < this.answerTableModified.length) {
              this.insertQuestionAnswer(question.id, index, value_answer);
            }

            try {
              if (answers[index].trim() == number) {
                actual_result = 'Key: ' + answers[index].trim();
              }
            } catch (e) {

            }

            selects = `<b style="margin-right: 8px">` + number + `</b><input style="margin-right: 4px" disabled ` + check + ` type="radio" id="radio_` + question.id + `_` + foundName.replace("Option", "") + `_` + number + `"  name="answer_` + question.id + `_` + foundName.replace("Option", "") + `">
            <span style="color: #b02a37; font-weight: bold; margin-right: 8px">` + actual_result + `</span>`
          }

          description = description.replace(String(found), selects);
          description = description.replace(regexName, '');
          allowReplace = true
        } else {
          allowReplace = false
        }
      }
    }
    this.summaryBandScore();
    return description;
  }

  private buildMultiChoice(question: Question) {
    let regexNumber = /\{[0-9]?[0-99]\}/i;
    let regexChar = /\{[A-Z]\}/i;
    let regexName = /\{Option[0-9]?[0-99]\}/i;
    let description = question.description
    let allowReplace = true
    while (allowReplace) {
      //case1: find number
      let found = description.match(new RegExp(regexNumber))

      if (found != null) {
        let foundName = String(description.match(new RegExp(regexName)))
        if (foundName != null) {
          foundName = String(foundName).replace("{", "").replace("}", "")
        }
        let number = String(found).replace("{", "").replace("}", "")

        let value_answer = '';
        try {
          let question_answer = "#question" + question.id + "_answer" + foundName.replace("Option", "");

          if (this.studentAnswers.has(question_answer)) {
            value_answer = this.studentAnswers.get(question_answer);
          }
        } catch (e) {

        }

        let selects = `<b style="margin-right: 8px">` + number + `</b><input type="checkbox" id="answer_` + question.id + `_` + foundName.replace("Option", "") + `_` + number + `"  name="answer_` + question.id + `_` + foundName.replace("Option", "") + `">`
        if (this.is_show_preview) {
          let check = '';
          if (value_answer == number) {
            check = 'checked';
          }
          let index = Number(foundName.replace("Option", "").trim())
          if (index > 0) {
            index = index - 1;
          } else {
            index = 0;
          }

          if (index < this.answerTableModified.length) {
            this.insertQuestionMutichoiceAnswer(question.id, index, value_answer);
          }

          let actual_result = '';
          let answers = question.result.split(',');
          for (let i = 0; i < answers.length; i++) {
            if (answers[i] == number) {
              actual_result = 'Key: ' + answers[i].trim();
              if (i < this.answerTableModified.length) {
                this.insertQuestionMutichoiceAnswer(question.id, i, value_answer);
              }
            }
          }

          selects = `<b style="margin-right: 8px">` + number + `</b><input ` + check + ` disabled type="checkbox" id="answer_` + question.id + `_` + foundName.replace("Option", "") + `_` + number + `"  name="answer_` + question.id + `_` + foundName.replace("Option", "") + `">
          <span style="color: #b02a37; font-weight: bold">` + actual_result + `</span>`
        }

        description = description.replace(regexNumber, selects);
        description = description.replace(regexName, '');
        allowReplace = true
      } else {
        //case2: find char
        found = description.match(new RegExp(regexChar))
        if (found != null) {
          let foundName = String(description.match(new RegExp(regexName)))
          if (foundName != null) {
            foundName = String(foundName).replace("{", "").replace("}", "")
          }
          let number = String(found).replace("{", "").replace("}", "")

          let value_answer = '';
          try {
            let question_answer = "#question" + question.id + "_answer" + foundName.replace("Option", "");

            if (this.studentAnswers.has(question_answer)) {
              value_answer = this.studentAnswers.get(question_answer);
            }
          } catch (e) {

          }
          let selects = `<b style="margin-right: 8px">` + number + `</b><input type="checkbox" id="answer_` + question.id + `_` + foundName.replace("Option", "") + `_` + number + `"  name="answer_` + question.id + `_` + foundName.replace("Option", "") + `">`
          if (this.is_show_preview) {
            let check = '';
            if (value_answer == number) {
              check = 'checked';
            }

            let actual_result = '';
            let answers = question.result.split(',');
            for (let i = 0; i < answers.length; i++) {
              if (answers[i] == number) {
                actual_result = 'Key: ' + answers[i].trim();
                if (i < this.answerTableModified.length) {
                  this.insertQuestionMutichoiceAnswer(question.id, i, value_answer);
                }
              }
            }


            selects = `<b style="margin-right: 8px">` + number + `</b><input disabled ` + check + ` type="checkbox" id="answer_` + question.id + `_` + foundName.replace("Option", "") + `_` + number + `"  name="answer_` + question.id + `_` + foundName.replace("Option", "") + `">
            <span style="color: #b02a37; font-weight: bold">` + actual_result + `</span>`
          }


          description = description.replace(regexChar, selects);
          description = description.replace(regexName, '');
          allowReplace = true
        } else {
          allowReplace = false
        }
      }
    }
    this.summaryBandScore();
    return description;
  }

  buildAnswerPreview() {
    for (let i = 0; i < this.answerNumber.length; i++) {
      this.answerPreview.set(i, this.studentAnswers.get('#' + this.answerNumber[i]))
    }
  }

  openPreview() {
    this.buildAnswerPreview();
    $("#previewModal").modal('show');
  }

  closePreview() {
    $("#previewModal").modal('hide');
  }

  openSubmitConfirm() {
    $("#confirmSubmitModal").modal('show');
  }

  closeSubmit() {
    $("#confirmSubmitModal").modal('hide');
  }

  onSubmit() {

    localStorage.setItem('q_taken_' + this.testDetail.topic_test.id, this.display);
    $("#confirmSubmitModal").modal('hide');
    localStorage.setItem('test_' + this.testDetail.topic_test.id, JSON.stringify(Array.from(this.studentAnswers)));
    this.router.navigateByUrl('listening/preview/' + this.testDetail.topic_test.alias).then(r => {
    });
  }

  private getREfListening() {
    this.dataRepository.getRefListening(this.testDetail.topic_test.id).subscribe((data: BaseResponse<TopicTest[]>) => {
      this.listenings = data.data;
    });
  }

  insertQuestionAnswer(questionId: number, optionIndex: number, value_answer) {
    for (let i = 0; i < this.answerTableModified.length; i++) {
      if (this.answerTableModified[i].id == questionId && this.answerTableModified[i].option == String((optionIndex + 1))) {
        this.answerTableModified[i].user_answer = value_answer;
        break;
      }
    }
  }

  insertQuestionMutichoiceAnswer(questionId: number, optionIndex: number, value_answer) {
    console.log("Insert questionId: " + questionId + " :optionIndex: " + optionIndex + " :value_answer: " + value_answer);
    for (let i = 0; i < this.answerTableModified.length; i++) {
      if (this.answerTableModified[i].user_answer == null || this.answerTableModified[i].user_answer == '') {
        if (this.answerTableModified[i].id == questionId && this.answerTableModified[i].option == String((optionIndex + 1))) {
          this.answerTableModified[i].user_answer = value_answer;
          break;
        }
      }

    }
  }

  checkValidAnswer(questionAnswer: QuestionAnswer) {
    let isValid = false;
    if(questionAnswer.user_answer.toLowerCase().trim() == questionAnswer.actual_answer.toLowerCase().trim()){
      isValid = true;
    }

    return isValid;
  }

  checkValidAnswerTable(question: QuestionAnswer, actual_answer: string) {
    let isValid = false;
    for (let i = 0; i < this.answerTableModified.length; i++) {
      if (this.answerTableModified[i].id == question.id) {
        if (actual_answer.trim().toLowerCase() == this.answerTableModified[i].actual_answer.trim().toLowerCase()) {
          if (this.answerTableModified[i].user_answer.trim().toLowerCase() == actual_answer.toLowerCase()) {
            console.log("User-answer: "+this.answerTableModified[i].user_answer.trim().toLowerCase());
            console.log("actual_answer: "+actual_answer);
            isValid = true;
            break
          }
        }
      }
    }

    return isValid;
  }

  getAnswer(question: QuestionAnswer) {
    let answer = question.actual_answer;
    // if (question.answer_type == AnswerType.MULTI_CHOICE) {
    //   for (let i = 0; i < this.answerTableModified.length; i++) {
    //     if (this.answerTableModified[i].id == question.id) {
    //       if (this.answerTableModified[i].actual_answer.trim().toLowerCase() == question.user_answer.trim().toLowerCase()) {
    //         answer = question.user_answer;
    //       }
    //     }
    //   }
    // }
    return answer;
  }

  summaryBandScore() {
    try {
      if (this.is_show_preview) {
        if (this.testDetail != null) {
          this.timeTaken = String(localStorage.getItem('q_taken_' + this.testDetail.topic_test.id));
          this.totalCorrect = 0;
          this.totalIncorrect = 0;
          this.totalUnanswered = 0;
          this.bandScore = 0;
          // console.log("this.answerTableModified: Size: "+ this.answerTableModified.length);
          // console.log(this.answerTableModified);

          for (let i = 0; i < this.answerTableModified.length; i++) {
            let answerQuestion = this.answerTableModified[i];
            if (answerQuestion.answer_type == AnswerType.MULTI_CHOICE) {
              if (this.checkValidAnswer(answerQuestion)) {
                this.totalCorrect += 1;
              } else {
                this.totalIncorrect += 1;
              }

              if (answerQuestion.user_answer == null || answerQuestion.user_answer == '') {
                this.totalUnanswered += 1;
              } else if (answerQuestion.answer_type == 2 && answerQuestion.user_answer == '-1') {
                this.totalUnanswered += 1;
              }
            } else {
              if (answerQuestion.actual_answer.trim().toLowerCase() == answerQuestion.user_answer.trim().toLowerCase()) {
                this.totalCorrect += 1;
              } else {
                this.totalIncorrect += 1;
              }

              if (answerQuestion.user_answer == null || answerQuestion.user_answer == '') {
                this.totalUnanswered += 1;
              } else if (answerQuestion.answer_type == 2 && answerQuestion.user_answer == '-1') {
                this.totalUnanswered += 1;
              }
            }
          }

          this.bandScore = 9.0 * this.totalCorrect / this.answerTableModified.length;
          let intVal = Math.floor(this.bandScore);
          if (this.bandScore - intVal > 0.5 && this.bandScore < intVal + 1) {
            this.bandScore = intVal + 0.5;
          } else if (this.bandScore > intVal && this.bandScore < intVal + 0.5) {
            this.bandScore = intVal;
          }

        }
      }
    } catch (e) {

    }
  }
}
