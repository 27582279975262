import {DataRepository} from "./data.repository";
import {Certification} from "../models/Certification";
import {DataService} from "../api/data.service";
import {Observable} from "rxjs";
import {BaseResponse} from "../models/BaseResponse";
import {Injectable} from "@angular/core";
import {Major} from "../models/Major";
import {Book} from "../models/Book";
import {TopicTest} from "../models/TopicTest";
import {Question} from "../models/Question";
import {User} from "../models/User";
import {LoginRequest} from "../models/LoginRequest";
import {LoginResponse} from "../models/LoginResponse";
import {HomeResponse} from "../models/HomeResponse";
import {TestDetail} from "../models/TestDetail";
import {Grammar} from "../models/Grammar";
import {Podcast} from "../models/Podcast";
import {EBook} from "../models/EBook";
import {Contact} from "../models/Contact";
import {Post} from "../models/Post";
import {PostPrefResponse} from "../models/PostPrefResponse";
import {MenuDataResponse} from "../models/MenuDataResponse";

@Injectable({providedIn: 'root'})
export class DataRepositoryImpl implements DataRepository{
  constructor(private data: DataService) {
  }

  getCertification(): Observable<BaseResponse<Certification[]>> {
    return this.data.getCertifications();
  }

  getCertificationById(id: number): Observable<BaseResponse<Certification>> {
    return this.data.getCertificationById(id);
  }

  getMajorById(id: number): Observable<BaseResponse<Major>> {
    return this.data.getMajorById(id);
  }

  getMajors(): Observable<BaseResponse<Major[]>> {
    return this.data.getMajors();
  }

  getBookById(id: number): Observable<BaseResponse<Book>> {
    return this.data.getBookById(id);
  }

  getBooks(): Observable<BaseResponse<Book[]>> {
    return this.data.getBooks();
  }

  getTestByName(alias: string): Observable<BaseResponse<TestDetail>> {
    return this.data.getTestByName(alias);
  }

  getTests(): Observable<BaseResponse<TopicTest[]>> {
    return this.data.getTests();
  }

  getQuestionById(id: number): Observable<BaseResponse<Question>> {
    return this.data.getQuestionById(id);
  }

  getQuestions(): Observable<BaseResponse<Question[]>> {
    return this.data.getQuestions();
  }

  createCertification(cert: Certification, file: File): Observable<BaseResponse<Certification>> {
    return this.data.createCertification(cert, file);
  }

  updateCertification(id: number, cert: Certification, file: File): Observable<BaseResponse<Certification>> {
    return this.data.updateCertification(id, cert, file);
  }

    createMajor(major: Major, file: File): Observable<BaseResponse<Major>> {
    return this.data.createMajor(major, file);
  }

  updateMajor(id: number, major: Major, file: File): Observable<BaseResponse<Major>> {
    return this.data.updateMajor(id, major, file);
  }

  createBook(book: Book, file: File): Observable<BaseResponse<Book>> {
    return this.data.createBook(book, file);
  }

  updateBook(id: number, book: Book, file: File): Observable<BaseResponse<Book>> {
    return this.data.updateBook(id, book, file);
  }

  createTest(test: TopicTest, file: File): Observable<BaseResponse<TopicTest>> {
    return this.data.createTest(test, file);
  }

  updateTest(id: number, test: TopicTest, file: File): Observable<BaseResponse<TopicTest>> {
    return this.data.updateTest(id, test, file);
  }

    createQuestion(question: Question, file: File): Observable<BaseResponse<Question>> {
    return this.data.createQuestion(question, file);
  }

  updateQuestion(id: number, question: Question, file: File): Observable<BaseResponse<Question>> {
    return this.data.updateQuestion(id, question, file);
  }

  getUsers(): Observable<BaseResponse<User[]>> {
    return this.data.getUsers();
  }

  login(request: LoginRequest): Observable<BaseResponse<LoginResponse>> {
    return this.data.login(request);
  }

  updateUser(id: number, user: User): Observable<BaseResponse<User>> {
    return this.data.updateUser(id, user);
  }

  getUserDetail(id: number): Observable<BaseResponse<User>> {
    return this.data.getUserDetail(id);
  }

  createUser(user: User): Observable<BaseResponse<User>> {
    return this.data.createUser(user);
  }

  resetPassword(id: number, password: string): Observable<BaseResponse<User>> {
    return this.data.resetPassword(id, password);
  }

  getHomeData(): Observable<BaseResponse<HomeResponse>> {
    return this.data.getHomeData();
  }

  getListeningList(page: number, page_size: number, major: string): Observable<BaseResponse<TopicTest[]>> {
    return this.data.getListeningList(page, page_size, major);
  }

  getReadingList(page: number, page_size: number, major: string): Observable<BaseResponse<TopicTest[]>> {
    return this.data.getReadingList(page, page_size, major);
  }

  getRefListening(testId: number): Observable<BaseResponse<TopicTest[]>> {
    return this.data.getRefListening(testId);
  }

  getRefReading(testId: number): Observable<BaseResponse<TopicTest[]>> {
    return this.data.getRefReading(testId);
  }

  getGrammar(page: number, page_size: number): Observable<BaseResponse<Grammar[]>> {
    return this.data.getGrammar(page, page_size);
  }

  getGrammarByName(alias: string): Observable<BaseResponse<Grammar>> {
    return this.data.getGrammarByName(alias);
  }

  getRefGrammars(grammarId: number): Observable<BaseResponse<Grammar[]>> {
    return this.data.getRefGrammars(grammarId);
  }

  getPodcast(podType: number, page: number, page_size: number, major: string): Observable<BaseResponse<Podcast[]>> {
    return this.data.getPodcast(podType, page, page_size, major);
  }

  getPodcastByName(alias: string): Observable<BaseResponse<Podcast>> {
    return this.data.getPodcastByName(alias);
  }

  getRefPodcast(podcastId: number): Observable<BaseResponse<Podcast[]>> {
    return this.data.getRefPodcast(podcastId);
  }

  getEbooks(page: number, page_size: number, major: string): Observable<BaseResponse<EBook[]>> {
    return this.data.getEbooks(page, page_size, major);
  }

    createContact(contact: Contact, captchaResponse: string): Observable<BaseResponse<Contact>> {
    return this.data.createContact(contact, captchaResponse);
  }

  getEbookByName(alias: string): Observable<BaseResponse<EBook>> {
    return this.data.getEbookByName(alias);
  }

  getRefEbook(ebookId: number): Observable<BaseResponse<EBook[]>> {
    return this.data.getRefEbook(ebookId);
  }

  getPostByName(alias: string): Observable<BaseResponse<Post>> {
    return this.data.getPostByName(alias);
  }

  getRefPosts(postId: number): Observable<BaseResponse<PostPrefResponse>> {
    return this.data.getRefPosts(postId);
  }

  getPosts(page: number, page_size: number, tag: string, searchText: string): Observable<BaseResponse<Post[]>> {
    return this.data.getPosts(page, page_size, tag, searchText);
  }

  getRightMenuData(): Observable<BaseResponse<MenuDataResponse>> {
    return this.data.getRightMenuData();
  }

  downloadBook(document: string, capchaToken: string): Observable<Blob> {
    return this.data.downloadBook(document, capchaToken)
  }
}
