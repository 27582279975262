import {Component, OnInit} from '@angular/core';
import {DataRepositoryImpl} from "../../../../domain/data.repositoryimpl";
import {ActivatedRoute} from "@angular/router";
import {BaseResponse} from "../../../../models/BaseResponse";
import {Grammar} from "../../../../models/Grammar";
import {faArrowRight, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-grammar-body-list',
  templateUrl: './grammar-body-list.component.html',
  styleUrls: ['./grammar-body-list.component.css']
})
export class GrammarBodyListComponent implements OnInit{
  loading: boolean = false;
  grammar: Grammar[];
  host = environment.apiUrl;
  item_count = 0;
  total_page: number = 0;
  page_size = 20;
  pages: number[];
  pageActive = 1;
  faArrowRight = faArrowRight
  faArrowLeft = faArrowLeft

  constructor(private repository: DataRepositoryImpl, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['page'] != null) {
      this.pageActive = Number(this.route.snapshot.queryParams['page']);
    }
    this.loading = true;
    this.getHomeData()
  }

  public getHomeData() {
    this.repository.getGrammar(this.pageActive - 1, this.page_size).subscribe((data: BaseResponse<Grammar[]>) => {
      this.grammar = data.data;
      this.total_page = (data.item_count / this.page_size) | 0;
      this.total_page += 1;
      console.log("total_page: " + this.total_page);
      console.log("data_size: ", data.data.length)
      this.pages = Array.from({length: this.total_page}, (v, k) => k + 1);
      console.log("pages: " + this.pages);
      this.loading = false;
    });
  }
}
