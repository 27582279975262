<app-main-nav-bar></app-main-nav-bar>
<app-main-search></app-main-search>
<div class="container-fluid py-5 wow fadeInUp grammar-detail-body" data-wow-delay="0.1s">
  <div class="container py-5">
    <div class="row g-5">
      <div class="col-lg-8">
        <!-- Blog Detail Start -->
        <div class="mb-5">
          <h1 class="mb-4">{{podcast.title}}</h1>
          <img *ngIf="podcast!=null && podcast.video==null" class="img-fluid w-100 rounded mb-5"
               src="{{host +'api/static?image='+ podcast.image}}" alt="">
          <div *ngIf="podcast!=null && podcast.video!=null" class="video-wrapper">
            <iframe *ngIf="podcast!=null && podcast.video!=null" class="video"
                    [src]="podcast.video | safe:'resourceUrl'"
                    width="560" height="315" frameborder="0"
                    allowfullscreen="allowfullscreen">
            </iframe>
          </div>
          <span style="text-align: justify" *ngIf="podcast!=null"
                [innerHTML]="podcast.description"></span>
        </div>
        <!-- Blog Detail End -->

        <!-- Comment List Start -->
        <!-- Comment List End -->

        <!-- Comment Form Start -->

        <!-- Comment Form End -->
      </div>

      <!-- Sidebar Start -->
      <div class="col-lg-4">

        <!-- Category Start -->
        <!--        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">-->
        <!--          <div class="section-title section-title-sm position-relative pb-3 mb-4">-->
        <!--            <h3 class="mb-0">Categories</h3>-->
        <!--          </div>-->
        <!--          <div class="link-animated d-flex flex-column justify-content-start">-->
        <!--            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Web-->
        <!--              Design</a>-->
        <!--            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Web-->
        <!--              Development</a>-->
        <!--            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Web-->
        <!--              Development</a>-->
        <!--            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Keyword-->
        <!--              Research</a>-->
        <!--            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Email-->
        <!--              Marketing</a>-->
        <!--          </div>-->
        <!--        </div>-->
        <!-- Category End -->

        <!-- Recent Post Start -->
        <div *ngIf="recents!=null" class="mb-5 wow slideInUp" data-wow-delay="0.1s">
          <div class="section-title section-title-sm position-relative pb-3 mb-4">
            <h3 class="mb-0">Recent Post</h3>
          </div>
          <ng-template ngFor let-itemReading [ngForOf]="recents" let-i="index">

            <div class="d-flex rounded overflow-hidden bg-light mb-3">
              <img class="img-fluid" src="{{host +'api/static?image='+ itemReading.image}}"
                   style="width: 100px; height: 100px; object-fit: cover;" alt="">
              <a href="posts/detail/{{ itemReading.alias }}"
                 class="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">{{itemReading.title}}
              </a>
            </div>
          </ng-template>

        </div>
        <!-- Recent Post End -->

        <!-- Image Start -->
        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
          <img src="img/blog-1.jpg" alt="" class="img-fluid rounded">
        </div>
        <!-- Image End -->

        <!-- Tags Start -->
        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
          <div class="section-title section-title-sm position-relative pb-3 mb-4">
            <h3 class="mb-0">Tag Cloud</h3>
          </div>
          <div class="d-flex flex-wrap m-n1">
            <ng-template ngFor let-tag [ngForOf]="tags" let-i="index">
              <a href="/posts?tag={{tag.alias}}" class="btn btn-light m-1">{{tag.title}}</a>
            </ng-template>
          </div>
        </div>
        <!-- Tags End -->
      </div>
      <!-- Sidebar End -->
    </div>
  </div>
</div>


<!--<section class="grammar-detail-body container">-->
<!--  <h2 *ngIf="podcast!=null" style="margin-top: 24px">-->
<!--    {{podcast.title}}-->
<!--  </h2>-->
<!--  <img *ngIf="podcast!=null" class="test-image" alt="{{podcast.title}}"-->
<!--       src="{{host +'api/static?image='+ podcast.image}}">-->

<!--  <div *ngIf="podcast!=null && podcast.video!=null" class="video-wrapper">-->
<!--    <iframe *ngIf="podcast!=null && podcast.video!=null" class="video" [src]="podcast.video | safe:'resourceUrl'"-->
<!--            width="560" height="315" frameborder="0"-->
<!--            allowfullscreen="allowfullscreen">-->
<!--    </iframe>-->
<!--  </div>-->

<!--  <div style="margin-top: 24px">-->
<!--        <span style="text-align: justify" *ngIf="podcast!=null"-->
<!--              [innerHTML]="podcast.description"></span>-->
<!--  </div>-->
<!--</section>-->

<section class="more-grammar">
  <div class="container py-5" *ngIf="podcasts.length!=0">
    <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
      <h5 class="fw-bold text-primary text-uppercase"><a href="/posts?pod_type=bbc-english">More Posts</a></h5>
    </div>
    <div class="row g-3 flex-wrap">
      <ng-template ngFor let-itemReading [ngForOf]="podcasts" let-i="index">
        <div class="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div
            class="service-item bg-light rounded d-flex flex-column align-items-center text-center">
            <div class="service-icon">
              <a href="/posts/detail/{{itemReading.alias}}">
                <img alt="{{itemReading.title}}" style="width: 100%; height: 127px;object-fit: cover;"
                     src="{{host +'api/static?image='+ itemReading.image}}"></a>
            </div>
            <h5 class="p-2"><a href="/posts/detail/{{itemReading.alias}}">{{itemReading.title}}</a></h5>
            <a class="btn btn-lg btn-primary rounded" href="/posts/detail/{{itemReading.alias}}">
              <fa-icon [icon]="faArrowRight"></fa-icon>
            </a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</section>
<app-main-footer></app-main-footer>
