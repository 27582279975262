import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {LoginResponse} from "../models/LoginResponse";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate() {
    let allow = false
    if (localStorage.getItem('LoginResponse')) {
      // logged in so return true
      let loginString = localStorage.getItem('LoginResponse');
      if (loginString != null) {
        let loginResponse: LoginResponse = JSON.parse(loginString);
        if (loginResponse != null) {
          if (loginResponse.user.is_staff || loginResponse.user.is_superuser) {
            allow = true;
          } else {
            allow = false;
          }
        }
      } else {
        allow = false;
      }
    }

    // not logged in so redirect to login page
    if(allow){
      return true
    }else{
      this.router.navigate(['/login']).then(r => {
        });
      return false;
    }
  }
}
