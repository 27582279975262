<script>
  function access() {
    var iframe = document.getElementById('iframe');
    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    console.log(innerDoc.body);
  }
</script>

<div>
  <div *ngIf="is_show_preview" class="container">
    <div class="stats-container">
      <div class="stats-right">
        <div class="upper-stats">
          <h1 style="margin-top: 20px" property="Band-Sore">Your Band Score: <b>{{bandScore}} / 9</b>
          </h1>
          <div class="progress">
            <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0" aria-valuemin="0"
                 aria-valuemax="100" style="width: 0%">
              0% Correct
            </div>
          </div>
        </div>
        <div class="lower-stats">
          <div class="left-lower">
            <div class="stats-block">
              <span class="stats-value" property="Total-question">{{answerTableModified.length}}</span>
              <span class="stats-title">Total Questions</span>
            </div>
            <div class="stats-block">
              <span class="stats-value" property="Score">{{answerTableModified.length - totalUnanswered}}/{{answerTableModified.length}} </span>
              <span class="stats-title" property="Marks">Marks</span>
            </div>
            <div class="stats-block">
              <span class="stats-value" property="Time-taken">{{timeTaken}}</span>
              <span class="stats-title" property="Label-Time-taken">Time Taken</span>
            </div>
          </div>

          <div class="right-lower">
            <div class="stats-block border-green">
              <span class="stats-value" property="Total-correct">{{totalCorrect}}</span>
              <span class="stats-title" property="Label-total-correct">Correct</span>
            </div>
            <div class="stats-block border-orange">
              <span class="stats-value" property="Total-incorrect">{{totalIncorrect}}</span>
              <span class="stats-title" property="Label-total-incorrect">Incorrect</span>
            </div>
            <div class="stats-block border-yellow">
              <span class="stats-value" property="Total-Unanswered">{{totalUnanswered}}</span>
              <span class="stats-title" property="Label-Total-Unanswered">Unanswered</span>
            </div>
          </div>
        </div>

        <h3 style="margin-top: 24px; padding-top: 24px" property="Title-Answer-Table">Answer Table</h3>

        <table class="table-answer" *ngIf="testDetail!=null">
          <tr>
            <td>
              <ng-container *ngFor=" let questionAnswer of answerTableModified; let i = index">
                <div *ngIf="questionAnswer.answer_type==2" class="table-answer-item"><span>{{"#" + (i + 1) + " : " + questionAnswer.actual_answer}}</span>
                  <fa-icon
                    *ngIf="!checkValidAnswerTable(questionAnswer, questionAnswer.actual_answer)"
                    [icon]="faCircleXmark"
                    style="color: red; margin-left: 4px"></fa-icon>
                  <fa-icon
                    *ngIf="checkValidAnswerTable(questionAnswer,questionAnswer.actual_answer)"
                    [icon]="faCheckCircle"
                    style="color: #48b565; margin-left: 4px"></fa-icon>
                </div>

                <div *ngIf="questionAnswer.answer_type!=2" class="table-answer-item"><span>{{"#" + (i + 1) + " : " + questionAnswer.actual_answer}}</span>
                  <fa-icon
                    *ngIf="!checkValidAnswer(questionAnswer)"
                    [icon]="faCircleXmark"
                    style="color: red; margin-left: 4px"></fa-icon>
                  <fa-icon
                    *ngIf="checkValidAnswer(questionAnswer)"
                    [icon]="faCheckCircle"
                    style="color: #48b565; margin-left: 4px"></fa-icon>
                </div>
              </ng-container>
            </td>
          </tr>

        </table>


      </div>
      <div class="stats-left">
        <app-answer-table></app-answer-table>
      </div>
    </div>
  </div>


  <div class="read-body">
    <div class="row flex-direction">
      <rsz-layout class="cell one" [directions]="[cells.a1]" [rFlex]="true">
        <h2 *ngIf="testDetail!=null" style="margin-top: 24px" property="Listening-Title-Detail">
          {{testDetail.topic_test.title}}
        </h2>
        <!--        <img *ngIf="testDetail!=null" class="test-image" alt="{{testDetail.topic_test.title}}"-->
        <!--             src="{{host+ testDetail.topic_test.image}}">-->
        <div id="video_wrapper" class="video-wrapper">
          <span *ngIf="testDetail!=null">
            <iframe property="Listening-Audio" id="iframe" *ngIf="testDetail.topic_test.video!=null" class="video" (load)="afterLoad()"
                    [src]="testDetail.topic_test.video | safe:'resourceUrl'"
                    width="560" height="315"
                    allowfullscreen="allowfullscreen">
            </iframe>
          </span>
        </div>

        <div style="margin-top: 24px" *ngIf="is_show_preview">
          <h3 class="audio-script" property="Title-Audio-Script">Audio Script</h3>
          <span style="text-align: justify" *ngIf="testDetail!=null"
                [innerHTML]="testDetail.topic_test.description | safe:'html'"></span>
        </div>
      </rsz-layout>
      <rsz-layout class="cell two" [directions]="[cells.a2]" [rFlex]="false">
        <ng-container *ngFor="let questionView of questionViews; let i = index">
          <hr style="margin-top: 48px; margin-bottom: 24px; color: #1f6832; height: 4px">
          <div style="margin-top: 24px"><h3>{{testDetail.questions[i].title}}</h3></div>
          <img property="Listening-Image-Detail" *ngIf="testDetail.questions[i].image!=null" class="image-question" alt="{{testDetail.topic_test.title}}"
               src="{{host +'api/static?image='+ testDetail.questions[i].image}}">
          <div property="Listening-Audio-Script" [innerHTML]="questionView | safe:'html'"></div>
        </ng-container>
        <hr style="margin-top: 48px; margin-bottom: 24px; color: #1f6832; height: 4px">
        <div *ngIf="is_show_preview==false" class="exam-section panel text-center">
          <p>
          </p><h4>---End of the Test---</h4>
          <p></p>
          <p>Please Submit to view your score, solution and explanations.</p>
          <p>
            <a property="Button-Submit-Answer" class="btn btn-success" (click)="openSubmitConfirm()">Submit</a>
          </p>
        </div>
      </rsz-layout>
    </div>
  </div>

  <section *ngIf="is_show_preview" class="more-listening">
    <div class="container py-5" *ngIf="listenings.length!=0">
      <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
        <h5 property="More-Listening-Link" class="fw-bold text-primary text-uppercase"><a href="/listening">More Listening Test</a></h5>
      </div>
      <div class="row g-3 flex-wrap">
        <ng-template ngFor let-itemReading [ngForOf]="listenings" let-i="index">
          <div property="MoreListeningItem" class="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.3s">
            <div
              class="service-item bg-light rounded d-flex flex-column align-items-center text-center">
              <div class="service-icon">
                <a href="/listening/detail/{{itemReading.alias}}" style="width: 100%; height: 100%;">
                  <img alt="{{itemReading.title}}" style="width: 100%; height: 100%;object-fit: cover;"
                       src="{{host +'api/static?image='+ itemReading.image}}">
                </a>
              </div>
              <h5 property="Listening-Title" class="m-2"><a href="/listening/detail/{{itemReading.alias}}">{{itemReading.title}}</a></h5>
              <h6 property="Listening-Category-Link" *ngIf="itemReading.major!=null" class="mb-3"><a
                href="/listening?major={{itemReading.major.alias}}">{{itemReading.major.title}}</a></h6>
              <h6 property="Listening-Category-title" *ngIf="itemReading.major==null" class="mb-3"><a href="/listening">General</a></h6>
              <a property="Link-To_Listening-Detail-Page" class="btn btn-lg btn-primary rounded" href="/listening/detail/{{itemReading.alias}}">
                <fa-icon [icon]="faArrowRight"></fa-icon>
              </a>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </section>

  <footer *ngIf="is_show_preview==false">
    <div class="clock-container">
      <div class="clock">{{display}}</div>
    </div>
    <div class="md-hue-2 md-scroll-shrink">
      <div class="group-bottom">
        <div class="number-list">
          <ng-container *ngFor="let item of answerNumber; let i = index">
            <div id="{{item}}" class="number-item">{{i + 1}}</div>
          </ng-container>
        </div>
        <div class="controller-button">
          <button class="btn btn-success" (click)="openSubmitConfirm()">Submit</button>
          <button class="btn btn-primary" type="button" style="margin-left: 8px" data-toggle="modal"
                  data-target="#previewModal" (click)="openPreview()">Review
          </button>
          <button class="btn btn-warning btn-solution" style="margin-left: 8px" (click)="onSubmit()">Solution</button>
        </div>
      </div>
    </div>
  </footer>
</div>

<div id="confirmSubmitModal" class="modal fade">
  <div class="modal-dialog small">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeSubmit()"><span
          aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title">Are you sure want to submit?</h4>
      </div>
      <div class="modal-body">
        <button class="btn btn-lg btn-success m-1" data-dismiss="modal" (click)="onSubmit()" style="margin-right: 8px">
          Submit and view Answers
        </button>
        <button class="btn btn-lg btn-primary m-1" data-dismiss="modal" (click)="closeSubmit()">
          Cancel
        </button>
      </div>

    </div>
  </div>
</div>

<div id="previewModal" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePreview()"><span
          aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title" id="myModalLabel">Review your answers</h4>
      </div>
      <div class="modal-body">
        <p>* This window is to review your answers only, you cannot change the answers in here</p>
        <div class="review-outer">
          <form *ngIf="studentAnswers!=null" id="form1" method="get">

            <ng-container *ngFor="let item of answerPreview | keyvalue">
              <div class="review-container">
                <div class="input-group">
                  <span class="input-group-addon" id="{{item}}">{{"Q" + (item.key + 1) }}</span>
                  <input *ngIf="item.value!=null" autocomplete="off" type="text"
                         class="form-control answers review-textboxes-filled" name="a" value="{{item.value}}">
                  <input *ngIf="item.value==null" autocomplete="off" type="text"
                         class="form-control answers review-textboxes" name="a" value="">
                </div>
              </div>
            </ng-container>


            <input type="hidden" name="t" value="10:03">
            <input id="btnSubmitReal" type="submit" value="Submit and view Answers" class="btn btn-lg btn-success"
                   style="display: none">

          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-lg btn-primary" data-dismiss="modal" (click)="closePreview()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
