<ng-template [ngIf]="loading">
  <app-loading></app-loading>
</ng-template>
<div class="container py-2" *ngIf="loading==false">
  <ng-template [ngIf]="majors!=null">
    <div class="major-list">
      <div id="{{'majorall'}}" class="major-item"><a href="/listening">All</a></div>
      <ng-container *ngFor="let item of majors">
        <div id="{{'major'+item.id}}" *ngIf="major == item.alias" class="major-item-selected"><a href="/listening?major={{item.alias}}">{{item.title}}</a></div>
        <div id="{{'major'+item.id}}" *ngIf="major != item.alias" class="major-item"><a href="/listening?major={{item.alias}}">{{item.title}}</a></div>
      </ng-container>
    </div>
  </ng-template>
  <div class="row g-3 mt-2">
    <ng-template ngFor let-itemReading [ngForOf]="listening" let-i="index">
      <div class="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.3s">
        <div
          class="service-item bg-light rounded d-flex flex-column align-items-center text-center">
          <div class="service-icon">
            <a style="width: 100%; height: 100%" href="/listening/detail/{{itemReading.alias}}">
              <img alt="{{itemReading.title}}" style="width: 100%; height: 100%;object-fit: cover;"
                   src="{{host +'api/static?image='+ itemReading.image}}">
            </a>
          </div>
          <h5 class="m-2">{{itemReading.title}}</h5>
          <h6 *ngIf="itemReading.major!=null" class="mb-3"><a href="/listening?major={{itemReading.major.alias}}">{{itemReading.major.title}}</a></h6>
          <h6 *ngIf="itemReading.major==null" class="mb-3"><a href="/listening">General</a></h6>
          <a class="btn btn-lg btn-primary rounded" href="/listening/detail/{{itemReading.alias}}">
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </a>
        </div>
      </div>
    </ng-template>
  </div>
  <br>

  <ng-container *ngIf="pages!=null">
    <div *ngIf="pages.length>1" class="d-flex justify-content-center wow slideInUp" data-wow-delay="0.1s">
      <nav aria-label="Page navigation">
        <ul class="pagination pagination-lg m-0">
          <li class="page-item">
            <a class="page-link rounded-0" *ngIf="pageActive>1" href="/listening?page={{this.pageActive-1 }}{{this.pageParams}}"
               aria-label="Previous">
              <span aria-hidden="true"><fa-icon [icon]="faArrowLeft"></fa-icon></span>
            </a>
          </li>

          <ng-container *ngFor="let number of pages">
            <li *ngIf="pageActive == number" class="page-item active"><a class="page-link"
                                                                         href="/listening?page={{number}}{{this.pageParams}}">{{number}}</a>
            </li>
            <li *ngIf="pageActive != number" class="page-item"><a class="page-link"
                                                                  href="/listening?page={{number}}{{this.pageParams}}">{{number}}</a></li>
          </ng-container>

          <li class="page-item">
            <a class="page-link rounded-0" *ngIf="pageActive<pages.length" href="/listening?page={{this.pageActive+1}}{{this.pageParams}}"
               aria-label="Next">
              <span aria-hidden="true"><fa-icon [icon]="faArrowRight"></fa-icon> </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </ng-container>
</div>
