<div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img class="w-100" src="assets/img/carousel-1.jpg" alt="Image">
        <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
          <div class="p-3" style="max-width: 900px;">
            <h5 class="text-white text-uppercase mb-3 animated slideInDown">Reading & Innovative</h5>
            <h1 class="display-1 text-white mb-md-4 animated zoomIn">Reading Digital Solution</h1>
            <a href="/reading" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Reading</a>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img class="w-100" src="assets/img/carousel-2.jpg" alt="Image">
        <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
          <div class="p-3" style="max-width: 900px;">
            <h5 class="text-white text-uppercase mb-3 animated slideInDown">Listening & Innovative</h5>
            <h1 class="display-1 text-white mb-md-4 animated zoomIn">Creative & Listening Digital Solution</h1>
            <a href="/listening" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Listening</a>
          </div>
        </div>
      </div>

      <div class="carousel-item">
        <img class="w-100" src="assets/img/carousel-2.jpg" alt="Image">
        <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
          <div class="p-3" style="max-width: 900px;">
            <h5 class="text-white text-uppercase mb-3 animated slideInDown">Grammar & Innovative</h5>
            <h1 class="display-1 text-white mb-md-4 animated zoomIn">Deep Memorization & Learning Digital Solution</h1>
            <a href="/grammar" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Grammar</a>
          </div>
        </div>
      </div>

    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
            data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
            data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
