import {Component, OnInit} from '@angular/core';
import {HomeResponse} from "../../../../models/HomeResponse";
import {TopicTest} from "../../../../models/TopicTest";
import {DataRepositoryImpl} from "../../../../domain/data.repositoryimpl";
import {BaseResponse} from "../../../../models/BaseResponse";
import {faArrowRight, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Major} from "../../../../models/Major";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {Meta} from "@angular/platform-browser";

@Component({
  selector: 'app-listening-body-list',
  templateUrl: './listening-body-list.component.html',
  styleUrls: ['./listening-body-list.component.css']
})
export class ListeningBodyListComponent implements OnInit {
  loading: boolean = false;
  homeResponse: HomeResponse;
  listening: TopicTest[];
  host = environment.apiUrl;
  item_count = 0;
  total_page: number = 0;
  page_size = 20;
  pages: number[];
  pageActive = 1;
  faArrowRight = faArrowRight
  faArrowLeft = faArrowLeft
  majors: Major[];
  pageParams = '';
  major ='';

  constructor(private repository: DataRepositoryImpl, private route: ActivatedRoute, private meta: Meta) {
    this.buildMetaTags()
  }

  private buildMetaTags(){
    this.meta.addTag({name: 'description', content: "IELTS BUILDER - Collection of listening ielts lesson with various of topic"});
    this.meta.addTag({name: 'keywords', content: 'English language, IELTS, English proficiency, Language skills, Speaking, Listening, Reading, Writing, Vocabulary, Grammar, Communication, Fluency, Academic English, Test preparation, Language proficiency, IELTS exam, Band score, Speaking test, Listening test, Reading test, Writing test, IELTS speaking tips, IELTS listening tips, IELTS reading tips, IELTS writing tips, IELTS study materials, Practice tests, Test format, Time management, IELTS score, IELTS band descriptors, Academic module, General Training module, IELTS registration, IELTS results, Test center, IELTS speaking topics, IELTS writing task 1, IELTS writing task 2, IELTS reading passages'})
    this.meta.addTag({name: 'title', content: 'IELTS BUILDER - Listening tests'})
    this.meta.addTag({name: 'robots', content: 'index, follow'})
    this.meta.addTag({name: 'googlebot', content: 'index, follow'})
    this.meta.addTag({property: 'og:type', content: 'Listening tests page'})
    this.meta.addTag({property: 'og:url', content: 'https://ieltsbuilder.com/listening'})
    this.meta.addTag({property: 'og:title', content: 'IELTS BUILDER - Listening tests'})
    this.meta.addTag({property: 'og:site_name', content: 'IELTS BUILDER'})
    this.meta.addTag({property: 'og:description', content: 'IELTS BUILDER - Collection of ielts listening lesson with various of topic'})
    this.meta.addTag({name: 'author', content: 'IELTS BUILDER'})
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.route.snapshot.queryParams['page'] != null) {
      this.pageActive = Number(this.route.snapshot.queryParams['page']);
    }
    if (this.route.snapshot.queryParams['major'] != null) {
      this.pageParams += '&major='+this.route.snapshot.queryParams['major'];
      this.major = this.route.snapshot.queryParams['major'];
    }
    this.getHomeData()
    this.getMajors()
  }

  public getHomeData() {
    this.repository.getListeningList(this.pageActive - 1, this.page_size, this.major).subscribe((data: BaseResponse<TopicTest[]>) => {
      console.log(data);
      this.listening = data.data;
      this.total_page = (data.item_count / this.page_size) | 0;
      this.total_page+=1;
      this.pages = Array.from({length:this.total_page},(v,k)=>k+1);
      this.loading = false;
    });
  }

  public getMajors() {
    this.repository.getMajors().subscribe((data: BaseResponse<Major[]>) => {
      console.log(data);
      this.majors = data.data;
    });
  }
}
