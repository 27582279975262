<!-- Blog Start -->
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
  <div class="container py-5">
    <div class="row g-5">
      <!-- Blog list Start -->
      <div class="col-lg-8">
        <div class="row g-5" *ngIf="posts!=null">
          <ng-template ngFor let-post [ngForOf]="posts" let-i="index">
            <div class="col-md-6 wow slideInUp" data-wow-delay="0.1s">
              <div class="blog-item bg-light rounded overflow-hidden">
                <div class="blog-img position-relative overflow-hidden">
                  <img class="img-fluid" src="{{host +'api/static?image='+ post.image}}" alt="">
                </div>
                <div class="p-4">
                  <div class="d-flex mb-3">
                    <small class="me-3">
                      <fa-icon [icon]="faUser" class="me-2"></fa-icon>
                      Admin</small>
                    <small>
                      <fa-icon [icon]="faCalendar" class="me-2"></fa-icon>
                      {{post.createdDate | date: 'dd MMM, yyyy'}}</small>
                  </div>
                  <h4 class="mb-3">{{post.title}}</h4>
                  <p class="short-description">{{post.short_description}}</p>
                </div>
                <a class="read-more" href="posts/detail/{{ post.alias }}">Read More
                  <fa-icon [icon]="faArrowRight"></fa-icon>
                </a>
              </div>
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="pages!=null">
          <div *ngIf="pages.length>1" class="d-flex justify-content-center wow slideInUp mt-4" data-wow-delay="0.1s">
            <nav aria-label="Page navigation">
              <ul class="pagination pagination-lg m-0">
                <li class="page-item">
                  <a class="page-link rounded-0" *ngIf="pageActive>1"
                     href="/posts?page={{this.pageActive-1 }}{{this.pageParams}}"
                     aria-label="Previous">
                    <span aria-hidden="true"><fa-icon [icon]="faArrowLeft"></fa-icon></span>
                  </a>
                </li>

                <ng-container *ngFor="let number of pages">
                  <li *ngIf="pageActive == number" class="page-item active"><a class="page-link"
                                                                               href="/posts?page={{number}}{{this.pageParams}}">{{number}}</a>
                  </li>
                  <li *ngIf="pageActive != number" class="page-item"><a class="page-link"
                                                                        href="/posts?page={{number}}{{this.pageParams}}">{{number}}</a>
                  </li>
                </ng-container>

                <li class="page-item">
                  <a class="page-link rounded-0" *ngIf="pageActive<pages.length"
                     href="/posts?page={{this.pageActive+1}}{{this.pageParams}}"
                     aria-label="Next">
                    <span aria-hidden="true"><fa-icon [icon]="faArrowRight"></fa-icon> </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </ng-container>
      </div>
      <!-- Blog list End -->

      <!-- Sidebar Start -->
      <div class="col-lg-4">
        <!-- Search Form Start -->
        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
          <div class="input-group">
            <input type="text" class="form-control p-3" (keydown.enter)="enterSearch($event)" value="{{searchText}}" placeholder="Keyword" (change)="changeTextSearch($event)">
            <button class="btn btn-primary px-4" (click)="searchPost()"><fa-icon [icon]="faSearch"></fa-icon></button>
          </div>
        </div>
        <!-- Search Form End -->

        <!-- Category Start -->
<!--        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">-->
<!--          <div class="section-title section-title-sm position-relative pb-3 mb-4">-->
<!--            <h3 class="mb-0">Categories</h3>-->
<!--          </div>-->
<!--          <div class="link-animated d-flex flex-column justify-content-start">-->
<!--            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Web-->
<!--              Design</a>-->
<!--            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Web-->
<!--              Development</a>-->
<!--            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Web-->
<!--              Development</a>-->
<!--            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Keyword-->
<!--              Research</a>-->
<!--            <a class="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2" href="#"><i class="bi bi-arrow-right me-2"></i>Email-->
<!--              Marketing</a>-->
<!--          </div>-->
<!--        </div>-->
        <!-- Category End -->

        <!-- Recent Post Start -->
        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
          <div class="section-title section-title-sm position-relative pb-3 mb-4">
            <h3 class="mb-0">Recent Post</h3>
          </div>
          <ng-container *ngFor="let recent_post of recent_posts">
          <div class="d-flex rounded overflow-hidden mb-3 bg-light">
            <img class="img-fluid" src="{{host +'api/static?image='+ recent_post.image}}" style="width: 100px; height: 100px; object-fit: cover;" alt="">
            <a href="" class="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0">{{recent_post.title}}
            </a>
          </div>
          </ng-container>
        </div>
        <!-- Recent Post End -->

        <!-- Image Start -->
        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
          <img src="img/blog-1.jpg" alt="" class="img-fluid rounded">
        </div>
        <!-- Image End -->

        <!-- Tags Start -->
        <div class="mb-5 wow slideInUp" data-wow-delay="0.1s">
          <div class="section-title section-title-sm position-relative pb-3 mb-4">
            <h3 class="mb-0">Tag Cloud</h3>
          </div>
          <div class="d-flex flex-wrap m-n1">
            <ng-template ngFor let-tag [ngForOf]="tags" let-i="index">
              <a href="/posts?tag={{tag.alias}}" class="btn btn-light m-1">{{tag.title}}</a>
            </ng-template>
          </div>
        </div>
        <!-- Tags End -->
      </div>
      <!-- Sidebar End -->
    </div>
  </div>
</div>
<!-- Blog End -->
