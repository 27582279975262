<app-main-nav-bar></app-main-nav-bar>
<app-main-search></app-main-search>
<section class="grammar-detail-body container">
  <h2 *ngIf="podcast!=null" style="margin-top: 24px">
    {{podcast.title}}
  </h2>
  <img *ngIf="podcast!=null" class="test-image" alt="{{podcast.title}}"
       src="{{host +'api/static?image='+ podcast.image}}">

  <div *ngIf="podcast!=null && podcast.video!=null" class="video-wrapper">
    <iframe *ngIf="podcast!=null && podcast.video!=null" class="video" [src]="podcast.video | safe:'resourceUrl'"
            width="560" height="315" frameborder="0"
            allowfullscreen="allowfullscreen">
    </iframe>
  </div>

  <div style="margin-top: 24px">
        <span style="text-align: justify" *ngIf="podcast!=null"
              [innerHTML]="podcast.description"></span>
  </div>
</section>

<section class="more-grammar">
  <div class="container py-5" *ngIf="podcasts.length!=0">
    <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
      <h5 class="fw-bold text-primary text-uppercase"><a href="/podcast?pod_type=bbc-english">More Podcast</a></h5>
    </div>
    <div class="row g-3 flex-wrap">
      <ng-template ngFor let-itemReading [ngForOf]="podcasts" let-i="index">
        <div class="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div
            class="service-item bg-light rounded d-flex flex-column align-items-center text-center">
            <div class="service-icon">
              <a href="/podcast/detail/{{itemReading.alias}}">
                <img alt="{{itemReading.title}}" style="width: 170px; height: 127px;object-fit: cover;"
                     src="{{host +'api/static?image='+ itemReading.image}}"></a>
            </div>
            <h5 class="m-2"><a href="/podcast/detail/{{itemReading.alias}}">{{itemReading.title}}</a></h5>
            <a class="btn btn-lg btn-primary rounded" href="/podcast/detail/{{itemReading.alias}}">
              <fa-icon [icon]="faArrowRight"></fa-icon>
            </a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</section>
<app-main-footer></app-main-footer>
