import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DataRepositoryImpl} from "./domain/data.repositoryimpl";
import {AuthGuard} from "./utils/AuthGuard";
import {DataService} from "./api/data.service";
import {ApiService} from "./api/api.service";
import { MainHeaderComponent } from './ui/main-header/main-header.component';
import { MainComponent } from './ui/page/main/main.component';
import { MainTopBarComponent } from './ui/main-top-bar/main-top-bar.component';
import { MainNavBarComponent } from './ui/main-nav-bar/main-nav-bar.component';
import { MainSearchComponent } from './ui/main-search/main-search.component';
import { MainFaceStartComponent } from './ui/main-face-start/main-face-start.component';
import { MainContentComponent } from './ui/main-content/main-content.component';
import { MainFooterComponent } from './ui/main-footer/main-footer.component';
import { PageNotFoundComponent } from './ui/page-not-found/page-not-found.component';
import { ListeningComponent } from './ui/page/listening/listening.component';
import { ReadingComponent } from './ui/page/reading/reading.component';
import { MainCarouselComponent } from './ui/main-carousel/main-carousel.component';
import { ReadingBodyListComponent } from './ui/page/reading/reading-body-list/reading-body-list.component';
import { ListeningBodyListComponent } from './ui/page/listening/listening-body-list/listening-body-list.component';
import { ReadingDetailComponent } from './ui/page/reading-detail/reading-detail.component';
import { ReadingDetailBodyComponent } from './ui/page/reading-detail/reading-detail-body/reading-detail-body.component';
import { ListeningDetailComponent } from './ui/page/listening-detail/listening-detail.component';
import { ListeningDetailBodyComponent } from './ui/page/listening-detail/listening-detail-body/listening-detail-body.component';
import {ResizableComponent} from "./ui/resizable/resizable.component";
import {DragDirective} from "./ui/resizable/drag.directive";
import {SafePipe} from "./utils/SafeHtmlPipe";
import { PreviewReadimgResultComponent } from './ui/page/reading-detail/preview-readimg-result/preview-readimg-result.component';
import { AnswerTableComponent } from './ui/page/reading-detail/answer-table/answer-table.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { PreviewListeningResultComponent } from './ui/page/listening-detail/preview-listening-result/preview-listening-result.component';
import { GrammarComponent } from './ui/page/grammar/grammar.component';
import { PodcastComponent } from './ui/page/podcast/podcast.component';
import { EbooksComponent } from './ui/page/ebooks/ebooks.component';
import { OutFeatureComponent } from './ui/page/out-feature/out-feature.component';
import { TeamMemberComponent } from './ui/page/team-member/team-member.component';
import { QuoteComponent } from './ui/page/quote/quote.component';
import { ContactComponent } from './ui/page/contact/contact.component';
import { GrammarBodyListComponent } from './ui/page/grammar/grammar-body-list/grammar-body-list.component';
import { GrammarDetailComponent } from './ui/page/grammar/grammar-detail/grammar-detail.component';
import { PodcastBodyListComponent } from './ui/page/podcast/podcast-body-list/podcast-body-list.component';
import { PodcastDetailComponent } from './ui/page/podcast/podcast-detail/podcast-detail.component';
import { EbooksBodyListComponent } from './ui/page/ebooks/ebooks-body-list/ebooks-body-list.component';
import { ContactFormComponent } from './ui/page/contact/contact-form/contact-form.component';
import { EbookDetailComponent } from './ui/page/ebooks/ebook-detail/ebook-detail.component';
import { PostDetailComponent } from './ui/page/post/post-detail/post-detail.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import { LoadingComponent } from './ui/loading/loading.component';
import { PostListComponent } from './ui/page/post/post-list/post-list.component';
import { PostListBodyComponent } from './ui/page/post/post-list-body/post-list-body.component';
import { PrivacyPolicyComponent } from './ui/page/privacy-policy/privacy-policy.component';
import {AswGoogleAdsenseModule} from "@asoftwareworld/google-adsense";

@NgModule({
    declarations: [
        AppComponent,
        MainHeaderComponent,
        MainComponent,
        MainTopBarComponent,
        MainNavBarComponent,
        MainSearchComponent,
        MainFaceStartComponent,
        MainContentComponent,
        MainFooterComponent,
        PageNotFoundComponent,
        ListeningComponent,
        ReadingComponent,
        MainCarouselComponent,
        ReadingBodyListComponent,
        ListeningBodyListComponent,
        ReadingDetailComponent,
        ReadingDetailBodyComponent,
        ListeningDetailComponent,
        ListeningDetailBodyComponent,
        ResizableComponent,
        DragDirective,
        PreviewReadimgResultComponent,
        AnswerTableComponent,
        SafePipe,
        PreviewListeningResultComponent,
        GrammarComponent,
        PodcastComponent,
        EbooksComponent,
        OutFeatureComponent,
        TeamMemberComponent,
        QuoteComponent,
        ContactComponent,
        GrammarBodyListComponent,
        GrammarDetailComponent,
        PodcastBodyListComponent,
        PodcastDetailComponent,
        EbooksBodyListComponent,
        ContactFormComponent,
        EbookDetailComponent,
        PostDetailComponent,
        LoadingComponent,
        PostListComponent,
        PostListBodyComponent,
        PrivacyPolicyComponent
    ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    RecaptchaV3Module,
    AswGoogleAdsenseModule.forRoot({
      adClient: 'ca-pub-8356666175852878',
      adSlot: 2573095084,
    }),
  ],
  providers: [ApiService,
    DataService,
    AuthGuard,
    DataRepositoryImpl,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LeQ41YmAAAAACwSNXD7043ileB1w0RxtmfThTu6" }
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
