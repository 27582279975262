import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from "./ui/page/main/main.component";
import {PageNotFoundComponent} from "./ui/page-not-found/page-not-found.component";
import {ListeningComponent} from "./ui/page/listening/listening.component";
import {ReadingComponent} from "./ui/page/reading/reading.component";
import {ReadingDetailComponent} from "./ui/page/reading-detail/reading-detail.component";
import {ListeningDetailComponent} from "./ui/page/listening-detail/listening-detail.component";
import {
  PreviewReadimgResultComponent
} from "./ui/page/reading-detail/preview-readimg-result/preview-readimg-result.component";
import {
  PreviewListeningResultComponent
} from "./ui/page/listening-detail/preview-listening-result/preview-listening-result.component";
import {GrammarComponent} from "./ui/page/grammar/grammar.component";
import {PodcastComponent} from "./ui/page/podcast/podcast.component";
import {EbooksComponent} from "./ui/page/ebooks/ebooks.component";
import {OutFeatureComponent} from "./ui/page/out-feature/out-feature.component";
import {TeamMemberComponent} from "./ui/page/team-member/team-member.component";
import {QuoteComponent} from "./ui/page/quote/quote.component";
import {ContactComponent} from "./ui/page/contact/contact.component";
import {GrammarDetailComponent} from "./ui/page/grammar/grammar-detail/grammar-detail.component";
import {PodcastDetailComponent} from "./ui/page/podcast/podcast-detail/podcast-detail.component";
import {EbookDetailComponent} from "./ui/page/ebooks/ebook-detail/ebook-detail.component";
import {PostDetailComponent} from "./ui/page/post/post-detail/post-detail.component";
import {PostListComponent} from "./ui/page/post/post-list/post-list.component";
import {environment} from "../environments/environment";
import {PrivacyPolicyComponent} from "./ui/page/privacy-policy/privacy-policy.component";

const routes: Routes = [
  {path: '', component: MainComponent, data: {title: 'Home'}},
  {path: 'listening', component: ListeningComponent, data: {title: 'Listening'}},
  {path: 'reading', component: ReadingComponent, data: {title: 'Reading'}},
  {path: 'grammar', component: GrammarComponent, data: {title: 'Grammar'}},
  {path: 'grammar/detail/:title', component: GrammarDetailComponent, data: {title: 'Grammar Detail'}},
  {path: 'podcast', component: PodcastComponent, data: {title: 'Podcast'}},
  {path: 'podcast/detail/:title', component: PodcastDetailComponent, data: {title: 'Podcast Detail'}},
  {path: 'ebooks', component: EbooksComponent, data: {title: 'Ebooks'}},
  {path: 'ebooks/detail/:title', component: EbookDetailComponent, data: {title: 'Ebook Detail'}},
  {path: 'our-feature', component: OutFeatureComponent, data: {title: 'Our Feature'}},
  {path: 'team-member', component: TeamMemberComponent, data: {title: 'Team Members'}},
  {path: 'quote', component: QuoteComponent, data: {title: 'Free Quotes'}},
  {path: 'contact', component: ContactComponent, data: {title: 'Contact'}},
  {path: 'reading/detail/:title', component: ReadingDetailComponent, data: {title: 'Reading Details'}},
  {path: 'reading/preview/:title', component: PreviewReadimgResultComponent, data: {title: 'Reading Preview'}},
  {path: 'listening/preview/:title', component: PreviewListeningResultComponent, data: {title: 'Listening Preview'}},
  {path: 'listening/detail/:title', component: ListeningDetailComponent, data: {title: 'Listening Details'}},

  {path: 'posts/detail/:title', component: PostDetailComponent, data: {title: 'Post Detail'}},
  {path: 'posts', component: PostListComponent, data: {title: 'Post List'}},

  {path: 'page-not-found', component: PageNotFoundComponent, data: {title: environment.app_name +" - "+ 'Page Not Found'}},
  {path: 'private-policy', component: PrivacyPolicyComponent, data: {title: environment.app_name +" - "+ 'Private policy'}},
  {path: '**', component: PageNotFoundComponent, data: {title: environment.app_name +" - "+ 'Page Not Found'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
