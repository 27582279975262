import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-listening',
  templateUrl: './listening.component.html',
  styleUrls: ['./listening.component.css']
})
export class ListeningComponent implements AfterViewInit, OnDestroy {
  title = environment.app_name + ' - ' + this.route.snapshot.data["title"];
  // @ViewChild('ins', {read: ElementRef, static: true}) ins!: ElementRef;

  constructor(private route: ActivatedRoute, @Inject(PLATFORM_ID) private platform: any) {

  }

  ngAfterViewInit(): void {
    // if (isPlatformBrowser(this.platform)) {
    //   this.push();
    // }
  }

  // push(): void {
  //   if (window) {
  //     try {
  //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //       ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
  //     } catch {
  //       // pass
  //     }
  //   }
  // }

  ngOnDestroy(): void {
    // const iframe = this.ins.nativeElement.querySelector('iframe');
    // if (iframe && iframe.contentWindow) {
    //   iframe.src = 'about:blank';
    //   iframe.remove();
    // }
  }
}
