import {Component, OnInit} from '@angular/core';
import {DataRepositoryImpl} from "../../domain/data.repositoryimpl";
import {HomeResponse} from "../../models/HomeResponse";
import {BaseResponse} from "../../models/BaseResponse";
import {TopicTest} from "../../models/TopicTest";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import {Post} from "../../models/Post";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css']
})
export class MainContentComponent implements OnInit {
  homeResponse: HomeResponse;
  listening: TopicTest[];
  readings: TopicTest[];
  posts: Post[];
  host = environment.apiUrl;
  faArrowRight = faArrowRight;
  faLinked = faLink;
  faTwitter = faLink;

  constructor(private repository: DataRepositoryImpl) {
  }
  ngOnInit(): void {
    this.getHomeData()
  }

  public getHomeData() {
    this.repository.getHomeData().subscribe((data: BaseResponse<HomeResponse>) => {
      console.log(data);
      this.homeResponse = data.data;
      this.listening = data.data.listening;
      this.readings = data.data.readings;
      this.posts = data.data.posts;
    });
  }

}
