import { Component } from '@angular/core';
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.css']
})
export class MainFooterComponent {
  faArrowUp = faArrowUp;
  app_name = environment.app_name;
  emai_contact = environment.emai_contact;
}
