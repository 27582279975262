<app-main-header></app-main-header>
<app-main-top-bar></app-main-top-bar>
<app-main-nav-bar></app-main-nav-bar>
<app-main-search></app-main-search>
<!--<div>-->
  <section id="not-found">
    <div id="title">{{app_name}} &bull; 404 Error Page</div>
    <div class="circles">
      <p>404<br>
        <small>PAGE NOT FOUND</small>
      </p>
      <span class="circle big"></span>
      <span class="circle med"></span>
      <span class="circle small"></span>
    </div>
  </section>
<!--</div>-->
<app-main-footer></app-main-footer>
