export class QuestionAnswer {
  id: number;
  option: string;
  user_answer: string;
  actual_answer: string;
  answer_type: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
