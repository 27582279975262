import { Component } from '@angular/core';

@Component({
  selector: 'app-out-feature',
  templateUrl: './out-feature.component.html',
  styleUrls: ['./out-feature.component.css']
})
export class OutFeatureComponent {

}
