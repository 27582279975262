<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s" style="background-color: white">
  <div class="container py-5">
    <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
      <h1 class="fw-bold text-primary text-uppercase">Contact Us</h1>
      <h2 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h2>
    </div>

    <div class="row g-5">
      <div class="contact-form col-lg-6" data-wow-delay="0.3s">
        <form [formGroup]="testForm" (ngSubmit)="onSubmit()">
          <div class="row g-3">
            <div class="col-md-6">
              <input type="text" formControlName="full_name" id="full_name" class="form-control border-0 bg-light px-4"
                     placeholder="Your Name"
                     style="height: 55px;">
              <div *ngIf="submitted && f['full_name'].errors" class="invalid-feedback d-block">
                <div *ngIf="f['full_name'].errors['required']">Your name is required</div>
              </div>
            </div>

            <div class="col-md-6">
              <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" formControlName="email" id="email"
                     class="form-control border-0 bg-light px-4"
                     placeholder="Your Email"
                     style="height: 55px;">
              <div *ngIf="submitted && f['email'].errors" class="invalid-feedback d-block">
                <div *ngIf="f['email'].errors['pattern']">Email is invalid format</div>
                <div *ngIf="f['email'].errors['required']">Email is required</div>
              </div>
            </div>
            <div class="col-12">
              <input type="text" formControlName="subject" id="subject" class="form-control border-0 bg-light px-4"
                     placeholder="Subject"
                     style="height: 55px;">
            </div>
            <div *ngIf="submitted && f['subject'].errors" class="invalid-feedback d-block">
              <div *ngIf="f['subject'].errors['required']">Subject is required</div>
            </div>
            <div class="col-12">
              <textarea formControlName="message" id="message" class="form-control border-0 bg-light px-4 py-3" rows="4"
                        placeholder="Message"></textarea>
            </div>
            <div *ngIf="submitted && f['message'].errors" class="invalid-feedback d-block">
              <div *ngIf="f['message'].errors['required']">Message is required</div>
            </div>
            <div class="col-12">
<!--              <div class="g-recaptcha" data-sitekey="6LeQ41YmAAAAACwSNXD7043ileB1w0RxtmfThTu6"></div>-->
<!--              <re-captcha (resolved)="handleToken($event)" siteKey="6LeQ41YmAAAAACwSNXD7043ileB1w0RxtmfThTu6"></re-captcha>-->
            </div>
            <div class="col-12">
<!--                            <button class="g-recaptcha"-->
<!--                      data-sitekey="reCAPTCHA_site_key"-->
<!--                      data-callback='onSubmit'-->
<!--                      data-action='submit'>Submit</button>-->
              <button
                      class="btn btn-primary w-100 py-3" type="submit">Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
