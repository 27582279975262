<app-main-nav-bar></app-main-nav-bar>
<app-main-search></app-main-search>
<section class="grammar-detail-body container">
  <h2 *ngIf="eBook!=null" style="margin-top: 24px">
    {{eBook.title}}
  </h2>
  <img *ngIf="eBook!=null" class="test-image" alt="{{eBook.title}}"
       src="{{host+ eBook.image}}">

  <div style="margin-top: 24px">
        <span style="text-align: justify" *ngIf="eBook!=null"
              [innerHTML]="eBook.description"></span>
  </div>
  <div>
    <span>Get FREE books here <button class="btn btn-primary py-3" (click)="downloadBook()">Download</button></span>
  </div>
</section>

<section class="more-grammar">
  <div class="container py-5" *ngIf="ebooks.length!=0">
    <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
      <h5 class="fw-bold text-primary text-uppercase"><a href="/ebooks">More Document</a></h5>
    </div>
    <div class="row g-3 flex-wrap">
      <ng-template ngFor let-itemReading [ngForOf]="ebooks" let-i="index">
        <div class="col-lg-3 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div
            class="service-item bg-light rounded d-flex flex-column align-items-center 0 text-center">
            <div class="service-icon">
              <a href="/ebooks/detail/{{itemReading.alias}}">
                <img alt="{{itemReading.title}}" style="width: 170px; height: 127px;object-fit: cover;"
                     src="{{host +'api/static?image='+ itemReading.image}}"></a>
            </div>
            <h5 class="m-2"><a href="/ebooks/detail/{{itemReading.alias}}">{{itemReading.title}}</a></h5>
            <h6 *ngIf="itemReading.major!=null" class="mb-3"><a
              href="/ebooks?major={{itemReading.major.alias}}">{{itemReading.major.title}}</a></h6>
            <h6 *ngIf="itemReading.major==null" class="mb-3"><a href="/ebooks">General</a></h6>
            <a class="btn btn-lg btn-primary rounded" href="/ebooks/detail/{{itemReading.alias}}">
              <fa-icon [icon]="faArrowRight"></fa-icon>
            </a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</section>
<app-main-footer></app-main-footer>
