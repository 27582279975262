import {Component, OnInit} from '@angular/core';
import {DataRepositoryImpl} from "../../../../domain/data.repositoryimpl";
import {ActivatedRoute} from "@angular/router";
import {BaseResponse} from "../../../../models/BaseResponse";
import {faArrowRight, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Podcast} from "../../../../models/Podcast";
import {Major} from "../../../../models/Major";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-podcast-body-list',
  templateUrl: './podcast-body-list.component.html',
  styleUrls: ['./podcast-body-list.component.css']
})
export class PodcastBodyListComponent implements OnInit {
  loading: boolean = false;
  podcasts: Podcast[];
  host = environment.apiUrl;
  item_count = 0;
  total_page: number = 0;
  page_size = 20;
  pages: number[];
  pageActive = 1;
  pageFilter = '';
  faArrowRight = faArrowRight
  faArrowLeft = faArrowLeft
  majors: Major[];
  pageParams = '';
  major ='';

  constructor(private repository: DataRepositoryImpl, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.route.snapshot.queryParams['page'] != null && !isNaN(this.route.snapshot.queryParams['page'])) {
      this.pageActive = Number(this.route.snapshot.queryParams['page']);
    }

    if (this.route.snapshot.queryParams['pod_type'] != null) {
      this.pageFilter = this.route.snapshot.queryParams['pod_type'];
      this.pageParams += '&pod_type='+this.route.snapshot.queryParams['pod_type'];
    }

    if (this.route.snapshot.queryParams['major'] != null) {
      this.pageParams += '&major='+this.route.snapshot.queryParams['major'];
      this.major = this.route.snapshot.queryParams['major'];
    }

    this.getPodcast()
    this.getMajors()
  }

  public getPodcast() {
    let podType = 1;
    console.log(this.pageFilter)
    if (this.pageFilter == 'youtube') {
      podType = 2
    }

    if (isNaN(this.pageActive)) {
      this.pageActive = 1;
    }
    this.repository.getPodcast(podType, this.pageActive - 1, this.page_size, this.major).subscribe((data: BaseResponse<Podcast[]>) => {
      this.podcasts = data.data;
      this.total_page = (data.item_count / this.page_size) | 0;
      this.total_page += 1;
      console.log("total_page: " + this.total_page);
      console.log("data_size: ", data.data.length)
      this.pages = Array.from({length: this.total_page}, (v, k) => k + 1);
      console.log("pages: " + this.pages);
      this.loading = false;
    });
  }

  public getMajors() {
    this.repository.getMajors().subscribe((data: BaseResponse<Major[]>) => {
      console.log(data);
      this.majors = data.data;
    });
  }
}
