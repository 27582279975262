import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DataRepositoryImpl} from "../../../../domain/data.repositoryimpl";
import {BaseResponse} from "../../../../models/BaseResponse";
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {EBook} from "../../../../models/EBook";
import {Meta, Title} from "@angular/platform-browser";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-ebook-detail',
  templateUrl: './ebook-detail.component.html',
  styleUrls: ['./ebook-detail.component.css']
})
export class EbookDetailComponent implements OnInit {
  host = environment.apiUrl
  eBook: EBook;
  answerNumber: string[] = [];
  faArrowRight = faArrowRight;
  ebooks: EBook[] = [];

  constructor(
    private route: ActivatedRoute,
    private repository: DataRepositoryImpl,
    private titleService: Title,
    private recaptchaV3Service: ReCaptchaV3Service,
    private meta: Meta) {
  }

  private buildMetaTags(){
    this.meta.addTag({name: 'description', content: this.eBook.seo_content});
    this.meta.addTag({name: 'keywords', content: 'English language, IELTS, English proficiency, Language skills, Speaking, Listening, Reading, Writing, Vocabulary, Grammar, Communication, Fluency, Academic English, Test preparation, Language proficiency, IELTS exam, Band score, Speaking test, Listening test, Reading test, Writing test, IELTS speaking tips, IELTS listening tips, IELTS reading tips, IELTS writing tips, IELTS study materials, Practice tests, Test format, Time management, IELTS score, IELTS band descriptors, Academic module, General Training module, IELTS registration, IELTS results, Test center, IELTS speaking topics, IELTS writing task 1, IELTS writing task 2, IELTS reading passages'})
    this.meta.addTag({name: 'title', content: 'IELTS BUILDER - '+this.eBook.title})
    this.meta.addTag({name: 'robots', content: 'index, follow'})
    this.meta.addTag({name: 'googlebot', content: 'index, follow'})
    this.meta.addTag({property: 'og:type', content: 'Ebooks detail page'})
    this.meta.addTag({property: 'og:url', content: 'https://ieltsbuilder.com/ebooks/detail/'+this.eBook.alias})
    this.meta.addTag({property: 'og:title', content: 'IELTS BUILDER - '+this.eBook.title})
    this.meta.addTag({property: 'og:site_name', content: 'IELTS BUILDER'})
    this.meta.addTag({property: 'og:description', content: this.eBook.seo_content})
    this.meta.addTag({name: 'author', content: 'IELTS BUILDER'})
  }

  ngOnInit(): void {
    this.getEbookDetail();
  }

  getEbookDetail() {
    let alias = String(this.route.snapshot.paramMap.get('title'));
    this.repository.getEbookByName(alias).subscribe((data: BaseResponse<EBook>) => {
      this.eBook = data.data;
      this.titleService.setTitle(environment.app_name + ' - ' + this.eBook.title);
      this.getRefEbook();
      this.buildMetaTags();
    }, () => {
      window.location.href = '/page-not-fount';
    });
  }

  private getRefEbook() {
    this.repository.getRefEbook(this.eBook.id).subscribe((data: BaseResponse<EBook[]>) => {
      this.ebooks = data.data;
    });
  }

  handleToken(captchaResponse: string) {
    this.repository.downloadBook(this.eBook.document, captchaResponse).subscribe((data: Blob) => {
      let url = window.URL.createObjectURL(data);
      let a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.download = this.eBook.document;
      a.click();
    }, (error) => {
      console.log(error)
      // window.location.href = '/page-not-fount';
    });
  }

  downloadBook() {
    this.recaptchaV3Service.execute('sendMessage')
      .subscribe((token) => this.handleToken(token));
  }
}
