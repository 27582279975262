import {Component} from '@angular/core';
import {Meta} from "@angular/platform-browser";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  constructor(private meta: Meta) {
    this.buildMetaTags()
  }

  private buildMetaTags() {
    this.meta.addTag({ name: 'description', content: 'Contact Page to send your message to IELTS BUILDER service.' });
    this.meta.addTag({name: 'keywords', content: 'contact, ielts, exams, testing, english test'})
    this.meta.addTag({name: 'title', content: 'Contact'})
    this.meta.addTag({name: 'robots', content: 'index, follow'})
    this.meta.addTag({name: 'googlebot', content: 'index, follow'})
    this.meta.addTag({property: 'og:type', content: 'contact'})
    this.meta.addTag({property: 'og:url', content: 'https://ieltsbuilder.com/contact'})
    this.meta.addTag({property: 'og:title', content: 'Contact'})
    this.meta.addTag({property: 'og:site_name', content: 'IELTS BUILDER'})
    this.meta.addTag({property: 'og:description', content: 'Contact Page to send your message to IELTS BUILDER service.'})
    this.meta.addTag({name: 'author', content: 'IELTS BUILDER'})
  }
}
