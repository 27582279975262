import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DataRepositoryImpl} from "../../../../domain/data.repositoryimpl";
import {BaseResponse} from "../../../../models/BaseResponse";
import {Grammar} from "../../../../models/Grammar";
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {Meta, Title} from "@angular/platform-browser";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-grammar-detail',
  templateUrl: './grammar-detail.component.html',
  styleUrls: ['./grammar-detail.component.css']
})
export class GrammarDetailComponent implements OnInit {
  host = environment.apiUrl
  grammar: Grammar;
  answerNumber: string[] = [];
  faArrowRight = faArrowRight;
  grammars: Grammar[] = [];

  constructor(private route: ActivatedRoute, private repository: DataRepositoryImpl, private titleService: Title, private meta: Meta) {
  }

  private buildMetaTags() {
    this.meta.addTag({name: 'description', content: this.grammar.seo_content});
    this.meta.addTag({
      name: 'keywords',
      content: 'English language, IELTS, English proficiency, Language skills, Speaking, Listening, Reading, Writing, Vocabulary, Grammar, Communication, Fluency, Academic English, Test preparation, Language proficiency, IELTS exam, Band score, Speaking test, Listening test, Reading test, Writing test, IELTS speaking tips, IELTS listening tips, IELTS reading tips, IELTS writing tips, IELTS study materials, Practice tests, Test format, Time management, IELTS score, IELTS band descriptors, Academic module, General Training module, IELTS registration, IELTS results, Test center, IELTS speaking topics, IELTS writing task 1, IELTS writing task 2, IELTS reading passages'
    })
    this.meta.addTag({name: 'title', content: 'IELTS BUILDER - ' + this.grammar.title})
    this.meta.addTag({name: 'robots', content: 'index, follow'})
    this.meta.addTag({name: 'googlebot', content: 'index, follow'})
    this.meta.addTag({property: 'og:type', content: 'Grammar detail page'})
    this.meta.addTag({
      property: 'og:url',
      content: 'https://ieltsbuilder.com/grammar/detail/' + this.grammar.alias
    })
    this.meta.addTag({property: 'og:title', content: 'IELTS BUILDER - ' + this.grammar.title})
    this.meta.addTag({property: 'og:site_name', content: 'IELTS BUILDER'})
    this.meta.addTag({property: 'og:description', content: this.grammar.seo_content})
    this.meta.addTag({name: 'author', content: 'IELTS BUILDER'})
  }

  ngOnInit(): void {
    if (this.grammar == null) {
      this.getReadingDetail();
    }
  }

  getReadingDetail() {
    let alias = String(this.route.snapshot.paramMap.get('title'));
    this.repository.getGrammarByName(alias).subscribe((data: BaseResponse<Grammar>) => {
      this.grammar = data.data;
      this.buildMetaTags();
      this.titleService.setTitle(environment.app_name + ' - ' + this.grammar.title);
      this.getREfGrammars();
    }, () => {
      window.location.href = '/page-not-fount';
    });
  }

  private getREfGrammars() {
    this.repository.getRefGrammars(this.grammar.id).subscribe((data: BaseResponse<Grammar[]>) => {
      this.grammars = data.data;
    });
  }
}
