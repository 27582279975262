import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-reading',
  templateUrl: './reading.component.html',
  styleUrls: ['./reading.component.css']
})
export class ReadingComponent implements AfterViewInit, OnDestroy {
  // @ViewChild('ins', {read: ElementRef, static: true}) ins!: ElementRef;

  constructor() {

  }
  ngAfterViewInit(): void {
    // this.push();
  }

  push(): void {
    // if (window) {
    //   try {
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
    //   } catch {
    //     // pass
    //   }
    // }
  }

   ngOnDestroy(): void {
    // const iframe = this.ins.nativeElement.querySelector('iframe');
    // if (iframe && iframe.contentWindow) {
    //   iframe.src = 'about:blank';
    //   iframe.remove();
    // }
  }
}
