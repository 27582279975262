import {ApiService} from "./api.service";
import {Injectable} from "@angular/core";
import {Certification} from "../models/Certification";
import {Major} from "../models/Major";
import {Book} from "../models/Book";
import {TopicTest} from "../models/TopicTest";
import {Question} from "../models/Question";
import {LoginRequest} from "../models/LoginRequest";
import {User} from "../models/User";
import {Contact} from "../models/Contact";

@Injectable({providedIn: 'root'})
export class DataService {

  constructor(private api: ApiService) {
  }

  public getCertifications() {
    return this.api.getCertifications()
  }

  public getUsers() {
    return this.api.getUsers()
  }

  public getCertificationById(id: number) {
    return this.api.getCertificationById(id)
  }

  public getMajorById(id: number) {
    return this.api.getMajorById(id)
  }

  public getMajors() {
    return this.api.getMajor()
  }

  public getBookById(id: number) {
    return this.api.getBookById(id)
  }

  public getBooks() {
    return this.api.getBooks()
  }

  public getTestByName(alias: string) {
    return this.api.getTestByName(alias)
  }

  public getTests() {
    return this.api.getTests()
  }

  public getQuestionById(id: number) {
    return this.api.getQuestionById(id)
  }

  public getQuestions() {
    return this.api.getQuestions()
  }

  public createCertification(cert: Certification, file: File) {
    return this.api.createCertification(cert, file)
  }

  public updateCertification(id: number, cert: Certification, file: File) {
    return this.api.updateCertification(id, cert, file)
  }

  public createMajor(major: Major, file: File) {
    return this.api.createMajor(major, file)
  }

  public updateMajor(id: number, major: Major, file: File) {
    return this.api.updateMajor(id, major, file)
  }

  public createBook(book: Book, file: File) {
    return this.api.createBook(book, file)
  }

  public updateBook(id: number, book: Book, file: File) {
    return this.api.updateBook(id, book, file)
  }

  public createTest(test: TopicTest, file: File) {
    return this.api.createTest(test, file)
  }

  public updateTest(id: number, test: TopicTest, file: File) {
    return this.api.updateTest(id, test, file)
  }

  public createQuestion(question: Question, file: File) {
    return this.api.createQuestion(question, file)
  }

  public updateQuestion(id: number, question: Question, file: File) {
    return this.api.updateQuestion(id, question, file)
  }

  public login(request: LoginRequest) {
    return this.api.login(request)
  }

  public updateUser(id: number, user: User) {
    return this.api.updateUser(id, user)
  }

  public getUserDetail(id: number) {
    return this.api.getUserDetail(id)
  }

  public createUser(user: User) {
    return this.api.createUser(user)
  }

  public resetPassword(id: number, password: string){
    return this.api.resetPassword(id, password)
  }

  public getHomeData(){
    return this.api.getHomeData()
  }

  public getReadingList(page: number, page_size: number, major: string){
    return this.api.getReading(page, page_size, major)
  }

  public getListeningList(page: number, page_size: number, major: string){
    return this.api.getListening(page, page_size, major)
  }

  public getRefListening(testId: number){
    return this.api.getRefListening(testId)
  }

  public getRefReading(testId: number){
    return this.api.getRefReading(testId)
  }

  public getGrammar(page: number, page_size: number){
    return this.api.getGrammar(page, page_size)
  }

  public getGrammarByName(alias: string){
    return this.api.getGrammarByName(alias)
  }

  public getRefGrammars(testId: number){
    return this.api.getRefGrammars(testId)
  }

  public getPodcast(podType: number, page: number, page_size: number, major: string){
    return this.api.getPodcast(podType, page, page_size, major)
  }

  public getPodcastByName(alias: string){
    return this.api.getPodcastByName(alias)
  }

  public getRefPodcast(podcastId: number){
    return this.api.getRefPodcast(podcastId)
  }

  public getEbooks(page: number, page_size: number, major: string){
    return this.api.getEbooks(page, page_size, major);
  }

  public createContact(contact: Contact, captchaResponse: string){
    return this.api.createContact(contact, captchaResponse);
  }

  public getRefEbook(ebookId: number){
    return this.api.getRefEbooks(ebookId)
  }

  public getEbookByName(alias: string){
    return this.api.getEbookByName(alias)
  }

  getPostByName(alias: string) {
    return this.api.getPostByName(alias);
  }

  getRefPosts(postId: number) {
    return this.api.getRefPosts(postId)
  }

  public getPosts(page: number, page_size: number, tag: string, searchText: string){
    return this.api.getPosts(page, page_size, tag, searchText);
  }

  public getRightMenuData() {
    return this.api.getRightMenuData();
  }

  downloadBook(document: string, capchaToken: string) {
    return this.api.downloadBook(document, capchaToken);
  }
}
