import { Component } from '@angular/core';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
  styleUrls: ['./main-nav-bar.component.css']
})

export class MainNavBarComponent {
  app_name = environment.app_name;
  faBars = faBars;
}
