import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Certification} from "../models/Certification";
import {catchError, map, Observable, throwError} from 'rxjs';
import {BaseResponse} from "../models/BaseResponse";
import {Major} from "../models/Major";
import {Book} from "../models/Book";
import {TopicTest} from "../models/TopicTest";
import {Question} from "../models/Question";
import {User} from "../models/User";
import {LoginRequest} from "../models/LoginRequest";
import {LoginResponse} from "../models/LoginResponse";
import {HomeResponse} from "../models/HomeResponse";
import {TestDetail} from "../models/TestDetail";
import {Grammar} from "../models/Grammar";
import {Podcast} from "../models/Podcast";
import {EBook} from "../models/EBook";
import {Contact} from "../models/Contact";
import {Post} from "../models/Post";
import {Router} from "@angular/router";
import {PostPrefResponse} from "../models/PostPrefResponse";
import {MenuDataResponse} from "../models/MenuDataResponse";
import {environment} from "../../environments/environment";

const API_URL = environment.apiUrl;

@Injectable({providedIn: 'root'})
export class ApiService {

  private getHeader() {
    let token = '';
    let loginString = localStorage.getItem('LoginResponse');
    if (loginString != null) {
      let loginResponse: LoginResponse = JSON.parse(loginString);
      if (loginResponse != null) {
        token = loginResponse.access_token
      }
    }
    return new HttpHeaders({
      // 'Authorization': 'Bearer ' + token
    });
  }

  constructor(private http: HttpClient, private router: Router) {
  }

  public getUsers() {

    return this.http.get<BaseResponse<User[]>>(API_URL + 'api/users', {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<User[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Users are not found!');
      })
    )
  }

  public getCertifications() {
    return this.http.get<BaseResponse<Certification[]>>(API_URL + 'api/certifications', {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Certification[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Certifications are not found!');
      })
    )
  }

  public getCertificationById(id: number) {
    let path = `api/certifications/detail/${id}`
    return this.http.get<BaseResponse<Certification>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Certification>) => {
        return data;
      }), catchError(error => {
        return throwError('Certification are not found!');
      })
    )
  }

  public getMajor() {
    return this.http.get<BaseResponse<Major[]>>(API_URL + 'api/frontend/major-list', {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Major[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Majors are not found!');
      })
    )
  }

  public getMajorById(id: number) {
    let path = `api/majors/detail/${id}`
    return this.http.get<BaseResponse<Major>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Major>) => {
        return data;
      }), catchError(error => {
        return throwError('Major are not found!');
      })
    )
  }

  public getBooks() {
    return this.http.get<BaseResponse<Book[]>>(API_URL + 'api/books', {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Book[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Books are not found!');
      })
    )
  }

  public getBookById(id: number) {
    let path = `api/books/detail/${id}`
    return this.http.get<BaseResponse<Book>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Book>) => {
        return data;
      }), catchError(error => {
        return throwError('Book are not found!');
      })
    )
  }

  public getTests() {
    return this.http.get<BaseResponse<TopicTest[]>>(API_URL + 'api/tests', {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<TopicTest[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Questions are not found!');
      })
    )
  }

  public getQuestions() {
    return this.http.get<BaseResponse<Question[]>>(API_URL + 'api/questions', {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Question[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Question are not found!');
      })
    )
  }

  public getQuestionById(id: number) {
    let path = `api/questions/detail/${id}`
    return this.http.get<BaseResponse<Question>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Question>) => {
        return data;
      }), catchError(error => {
        return throwError('Question are not found!');
      })
    )
  }

  public createCertification(cert: Certification, file: File) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', cert.title);
    return this.http.post<BaseResponse<Certification>>(API_URL + 'api/certifications', formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Certification>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot create certification!');
      })
    )
  }

  public updateCertification(id: number, cert: Certification, file: File) {
    let path = `api/certifications/edit/${id}`
    let formData = new FormData();
    if (file != null) {
      formData.append('file', file);
    }
    formData.append('title', cert.title);
    return this.http.put<BaseResponse<Certification>>(API_URL + path, formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Certification>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot update certification!');
      })
    )
  }

  public createMajor(major: Major, file: File) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', major.title);

    return this.http.post<BaseResponse<Major>>(API_URL + 'api/majors', formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Major>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot create major!');
      })
    )
  }

  public updateMajor(id: number, major: Major, file: File) {
    let path = `api/majors/edit/${id}`
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', major.title);
    return this.http.put<BaseResponse<Major>>(API_URL + path, formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Major>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot update major!');
      })
    )
  }

  public createBook(book: Book, file: File) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', book.title);
    if (book.majorId != null) {
      formData.append('majorId', String(book.majorId));
    }
    formData.append('description', book.description);

    return this.http.post<BaseResponse<Book>>(API_URL + 'api/books', formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Book>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot create book!');
      })
    )
  }

  public updateBook(id: number, book: Book, file: File) {
    let path = `api/books/edit/${id}`
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', book.title);
    if (book.majorId != null) {
      formData.append('majorId', String(book.majorId));
    }
    formData.append('description', book.description);
    return this.http.put<BaseResponse<Book>>(API_URL + path, formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Book>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot update book!');
      })
    )
  }

  public createTest(test: TopicTest, file: File) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', test.title);
    if (test.majorId != null) {
      formData.append('majorId', String(test.majorId));
    }
    if (test.bookId != null) {
      formData.append('bookId', String(test.bookId));
    }
    if (test.video != null) {
      formData.append('video', String(test.video));
    }
    if (test.testType != null) {
      formData.append('testType', String(test.testType));
    }
    formData.append('description', test.description);

    return this.http.post<BaseResponse<TopicTest>>(API_URL + 'api/tests', formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<TopicTest>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot create test!');
      })
    )
  }

  public updateTest(id: number, test: TopicTest, file: File) {
    let path = `api/tests/edit/${id}`;
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', test.title);
    if (test.majorId != null) {
      formData.append('majorId', String(test.majorId));
    }
    if (test.bookId != null) {
      formData.append('bookId', String(test.bookId));
    }
    if (test.video != null) {
      formData.append('video', String(test.video));
    }
    if (test.testType != null) {
      formData.append('testType', String(test.testType));
    }
    formData.append('description', test.description);
    return this.http.put<BaseResponse<TopicTest>>(API_URL + path, formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<TopicTest>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot update test!');
      })
    )
  }

  public createQuestion(question: Question, file: File) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', question.title);
    formData.append('result', String(question.result));
    formData.append('note', String(question.note));
    formData.append('description', String(question.description));
    formData.append('testId', String(question.testId));

    return this.http.post<BaseResponse<Question>>(API_URL + 'api/questions', formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Question>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot create test!');
      })
    );
  }

  public updateQuestion(id: number, question: Question, file: File) {
    let path = `api/questions/edit/${id}`
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', question.title);
    formData.append('result', String(question.result));
    formData.append('note', String(question.note));
    formData.append('description', String(question.description));
    formData.append('testId', String(question.testId));
    return this.http.put<BaseResponse<Question>>(API_URL + path, formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Question>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot update question!');
      }));
  }

  public login(request: LoginRequest) {
    return this.http.post<BaseResponse<LoginResponse>>(API_URL + 'api/authentication', request).pipe(
      map((data: BaseResponse<LoginResponse>) => {
        return data;
      }), catchError((error: HttpErrorResponse) => {
        // console.log('oops', error.response)
        return throwError(error.error);
      })
    );
  }

  public updateUser(id: number, user: User) {
    let path = `api/users/edit/${id}`
    return this.http.put<BaseResponse<User>>(API_URL + path, user, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<User>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot update user!');
      }));
  }

  public getUserDetail(id: number) {
    let path = `api/users/detail/${id}`
    return this.http.get<BaseResponse<User>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<User>) => {
        return data;
      }), catchError(error => {
        return throwError('User is not found!');
      })
    )
  }

  public createUser(user: User) {
    let path = `api/users`
    return this.http.post<BaseResponse<User>>(API_URL + path, user, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<User>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot create user!');
      }));
  }

  public resetPassword(id: number, password: string) {
    let path = `api/users/reset-password/${id}`
    let formData = new FormData();
    formData.append('password', password);
    return this.http.put<BaseResponse<User>>(API_URL + path, formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<User>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot reset password!');
      }));
  }

  // Frontend api
  public getHomeData() {
    return this.http.get<BaseResponse<HomeResponse>>(API_URL + 'api/frontend/home-data', {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<HomeResponse>) => {
        return data;
      }), catchError(error => {
        return throwError('HomeResponse are not found!');
      })
    )
  }

  public getReading(page: number, page_size: number, major: string) {
    let requestParams = {"page_num": page, "page_size": page_size, "major": major};
    return this.http.get<BaseResponse<TopicTest[]>>(API_URL + 'api/frontend/reading-list', {
      headers: this.getHeader(),
      params: requestParams
    }).pipe(
      map((data: BaseResponse<TopicTest[]>) => {
        return data;
      }), catchError(error => {
        return throwError('HomeResponse are not found!');
      })
    )
  }

  public getListening(page: number, page_size: number, major: string) {
    let requestParams = {"page_num": page, "page_size": page_size, "major": major};
    return this.http.get<BaseResponse<TopicTest[]>>(API_URL + 'api/frontend/listening-list', {
      headers: this.getHeader(),
      params: requestParams
    }).pipe(
      map((data: BaseResponse<TopicTest[]>) => {
        return data;
      }), catchError(error => {
        return throwError('HomeResponse are not found!');
      })
    )
  }

  public getTestByName(alias: string) {
    let path = `api/frontend/tests/detail/${alias}`
    return this.http.get<BaseResponse<TestDetail>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<TestDetail>) => {
        return data;
      }), catchError(error => {
        return throwError('Test is not found!');
      })
    )
  }

  public getRefListening(testId: number) {
    let path = `api/frontend/listening-ref/${testId}`
    return this.http.get<BaseResponse<TopicTest[]>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<TopicTest[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Test is not found!');
      })
    )
  }

  public getRefReading(testId: number) {
    let path = `api/frontend/reading-ref/${testId}`
    return this.http.get<BaseResponse<TopicTest[]>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<TopicTest[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Test is not found!');
      })
    )
  }

  public getGrammar(page: number, page_size: number) {
    let requestParams = {"page_num": page, "page_size": page_size};
    return this.http.get<BaseResponse<Grammar[]>>(API_URL + 'api/frontend/grammar-list', {
      headers: this.getHeader(),
      params: requestParams
    }).pipe(
      map((data: BaseResponse<Grammar[]>) => {
        return data;
      }), catchError(error => {
        return throwError('HomeResponse are not found!');
      })
    )
  }

  public getGrammarByName(alias: string) {
    let path = `api/frontend/grammar/detail/${alias}`
    return this.http.get<BaseResponse<Grammar>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Grammar>) => {
        return data;
      }), catchError(error => {
        return throwError('Test is not found!');
      })
    )
  }

  public getRefGrammars(grammarId: number) {
    let path = `api/frontend/grammar-ref/${grammarId}`
    return this.http.get<BaseResponse<Grammar[]>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Grammar[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Grammars is not found!');
      })
    )
  }

  public getPodcast(podType: number, page: number, page_size: number, major: string) {
    let requestParams = {"page_num": page, "page_size": page_size, 'podType': podType, 'major': major};
    return this.http.get<BaseResponse<Podcast[]>>(API_URL + 'api/frontend/podcast-list', {
      headers: this.getHeader(),
      params: requestParams
    }).pipe(
      map((data: BaseResponse<Podcast[]>) => {
        return data;
      }), catchError(error => {
        return throwError('HomeResponse are not found!');
      })
    )
  }

  public getPodcastByName(alias: string) {
    let path = `api/frontend/podcast/detail/${alias}`
    return this.http.get<BaseResponse<Podcast>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Podcast>) => {
        return data;
      }), catchError(error => {
        return throwError('Test is not found!');
      })
    )
  }

  public getRefPodcast(podcastId: number) {
    let path = `api/frontend/podcast-ref/${podcastId}`
    return this.http.get<BaseResponse<Podcast[]>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Podcast[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Grammars is not found!');
      })
    )
  }

  public getEbooks(page: number, page_size: number, major: string) {
    let requestParams = {"page_num": page, "page_size": page_size, 'major': major};
    return this.http.get<BaseResponse<EBook[]>>(API_URL + 'api/frontend/ebooks-list', {
      headers: this.getHeader(),
      params: requestParams
    }).pipe(
      map((data: BaseResponse<EBook[]>) => {
        return data;
      }), catchError(error => {
        return throwError('HomeResponse are not found!');
      })
    )
  }

  public createContact(contact: Contact, captchaResponse: string) {
    let formData = new FormData();
    formData.append('full_name', contact.full_name);
    formData.append('email', contact.email);
    formData.append('subject', contact.subject);
    formData.append('message', contact.message);
    formData.append('captcha_token', captchaResponse);
    return this.http.post<BaseResponse<Contact>>(API_URL + 'api/frontend/contact', formData, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Contact>) => {
        return data;
      }), catchError(error => {
        return throwError('Cannot create certification!');
      })
    )
  }

  public getEbookByName(alias: string) {
    let path = `api/frontend/ebooks/detail/${alias}`
    return this.http.get<BaseResponse<EBook>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<EBook>) => {
        return data;
      }), catchError(error => {
        return throwError('EBook is not found!');
      })
    )
  }

  public getRefEbooks(ebookId: number) {
    let path = `api/frontend/ebooks-ref/${ebookId}`
    return this.http.get<BaseResponse<EBook[]>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<EBook[]>) => {
        return data;
      }), catchError(error => {
        return throwError('EBook is not found!');
      })
    )
  }

  public getPostByName(alias: string) {
    let path = `api/frontend/posts/detail/${alias}`
    return this.http.get<BaseResponse<Post>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<Post>) => {
        return data;
      }), catchError(error => {
        return throwError('Post is not found!');
      })
    )
  }

  public getRefPosts(postId: number) {
    let path = `api/frontend/posts-ref/${postId}`
    return this.http.get<BaseResponse<PostPrefResponse>>(API_URL + path, {headers: this.getHeader()}).pipe(
      map((data: BaseResponse<PostPrefResponse>) => {
        return data;
      }), catchError(error => {
        return throwError('Post is not found!');
      })
    )
  }

  public getPosts(page: number, page_size: number, tag: string, searchText: string) {
    let requestParams = {"page_num": page, "page_size": page_size, 'tag': tag, 'searchText': searchText};
    return this.http.get<BaseResponse<Post[]>>(API_URL + 'api/frontend/posts-list', {
      headers: this.getHeader(),
      params: requestParams
    }).pipe(
      map((data: BaseResponse<Post[]>) => {
        return data;
      }), catchError(error => {
        return throwError('Post are not found!');
      })
    )
  }

  public getRightMenuData() {
    return this.http.get<BaseResponse<MenuDataResponse>>(API_URL + 'api/frontend/menu-data-list', {
      headers: this.getHeader()
    }).pipe(
      map((data: BaseResponse<MenuDataResponse>) => {
        return data;
      }), catchError(error => {
        return throwError('Post are not found!');
      })
    )
  }

  downloadBook(document: string, capchaToken: string): Observable<Blob> {
    let formData = new FormData();
    const httpOptions = {responseType: ('blob' as 'json')};
    formData.append('document', document);
    formData.append('captcha_token', capchaToken);
    return this.http.post<Blob>(API_URL + 'api/frontend/download', formData, httpOptions).pipe()
  }
}
