import {Component, OnInit} from '@angular/core';
import {TopicTest} from "../../../../models/TopicTest";
import {DataRepositoryImpl} from "../../../../domain/data.repositoryimpl";
import {BaseResponse} from "../../../../models/BaseResponse";
import {ActivatedRoute} from "@angular/router";
import {faArrowRight, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Major} from "../../../../models/Major";
import {environment} from "../../../../../environments/environment";
import {Meta} from "@angular/platform-browser";

@Component({
  selector: 'app-reading-body-list',
  templateUrl: './reading-body-list.component.html',
  styleUrls: ['./reading-body-list.component.css']
})
export class ReadingBodyListComponent implements OnInit {
  loading: boolean = false;
  majors: Major[];
  readings: TopicTest[];
  host = environment.apiUrl;
  item_count = 0;
  total_page: number = 0;
  page_size = 50;
  pages: number[];
  pageActive = 1;
  faArrowRight = faArrowRight
  faArrowLeft = faArrowLeft
  pageParams = '';
  major ='';

  constructor(private repository: DataRepositoryImpl, private route: ActivatedRoute, private meta: Meta) {
    this.buildMetaTags();
  }

  private buildMetaTags(){
    this.meta.addTag({name: 'description', content: "IELTS BUILDER - Collection of ielts reading lesson with various of topic"});
    this.meta.addTag({name: 'keywords', content: 'English language, IELTS, English proficiency, Language skills, Speaking, Listening, Reading, Writing, Vocabulary, Grammar, Communication, Fluency, Academic English, Test preparation, Language proficiency, IELTS exam, Band score, Speaking test, Listening test, Reading test, Writing test, IELTS speaking tips, IELTS listening tips, IELTS reading tips, IELTS writing tips, IELTS study materials, Practice tests, Test format, Time management, IELTS score, IELTS band descriptors, Academic module, General Training module, IELTS registration, IELTS results, Test center, IELTS speaking topics, IELTS writing task 1, IELTS writing task 2, IELTS reading passages'})
    this.meta.addTag({name: 'title', content: 'IELTS BUILDER - Reading tests'})
    this.meta.addTag({name: 'robots', content: 'index, follow'})
    this.meta.addTag({name: 'googlebot', content: 'index, follow'})
    this.meta.addTag({property: 'og:type', content: 'Reading tests page'})
    this.meta.addTag({property: 'og:url', content: 'https://ieltsbuilder.com/reading'})
    this.meta.addTag({property: 'og:title', content: 'IELTS BUILDER - Reading tests'})
    this.meta.addTag({property: 'og:site_name', content: 'IELTS BUILDER'})
    this.meta.addTag({property: 'og:description', content: 'IELTS BUILDER - Collection of ielts reading lesson with various of topic'})
    this.meta.addTag({name: 'author', content: 'IELTS BUILDER'})
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['page'] != null) {
      this.pageActive = Number(this.route.snapshot.queryParams['page']);
    }
    if (this.route.snapshot.queryParams['major'] != null) {
      this.pageParams += '&major='+this.route.snapshot.queryParams['major'];
      this.major = this.route.snapshot.queryParams['major'];
    }
    this.loading = true
    this.getHomeData();
    this.getMajors();
  }

  public getHomeData() {
    this.repository.getReadingList(this.pageActive - 1, this.page_size, this.major).subscribe((data: BaseResponse<TopicTest[]>) => {
      // console.log(data);
      this.readings = data.data;
      this.total_page = (data.item_count / this.page_size) | 0;
      this.total_page += 1;
      // console.log("total_page: " + this.total_page);
      // console.log("data_size: ", data.data.length)
      this.pages = Array.from({length: this.total_page}, (v, k) => k + 1);
      // console.log("pages: " + this.pages);
      this.loading = false
    });
  }

  public getMajors() {
    this.repository.getMajors().subscribe((data: BaseResponse<Major[]>) => {
      console.log(data);
      this.majors = data.data;
    });
  }
}
