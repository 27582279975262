import { Component } from '@angular/core';

@Component({
  selector: 'app-main-top-bar',
  templateUrl: './main-top-bar.component.html',
  styleUrls: ['./main-top-bar.component.css']
})
export class MainTopBarComponent {

}
