<h3>Band score</h3>
<table class="table">
  <thead>
  <tr style="background-color: gray; color:white">
    <th>Level</th>
    <th>Band</th>
  </tr>
  </thead>
  <tbody>
  <tr class="vgood">
    <td>Expert</td>
    <td>9</td>
  </tr>
  <tr class="vgood">
    <td>Very Good</td>
    <td>8.5</td>
  </tr>
  <tr class="vgood">
    <td>Very Good</td>
    <td>8</td>
  </tr>
  <tr class="good">
    <td>Good</td>
    <td>7.5</td>
  </tr>
  <tr class="good">
    <td>Good</td>
    <td>7</td>
  </tr>
  <tr class="compenent">
    <td>Competent</td>
    <td>6.5</td>
  </tr>
  <tr class="compenent">
    <td>Competent</td>
    <td>6</td>
  </tr>
  <tr class="modest">
    <td>Modest</td>
    <td>5.5</td>
  </tr>
  <tr class="modest">
    <td>Modest</td>
    <td>5</td>
  </tr>
  <tr class="active">
    <td>Limited</td>
    <td>4.5</td>
  </tr>
  <tr class="active">
    <td>Limited</td>
    <td>4</td>
  </tr>
  <tr class="active">
    <td>Extremely Limited</td>
    <td>&lt; 3.5</td>
  </tr>
  </tbody>
</table>
