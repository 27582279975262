<!-- Navbar & Carousel Start -->
<div class="container-fluid position-relative p-0">
  <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
    <a href="index.html" class="navbar-brand p-0">
      <h1 class="m-0"><i class="fa fa-user-tie me-2"></i>{{app_name}}</h1>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <fa-icon [icon]="faBars"></fa-icon>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav ms-auto py-0">
        <a href="index.html" class="nav-item nav-link active">Home</a>
        <a href="/listening" class="nav-item nav-link">Listening</a>
        <a href="/reading" class="nav-item nav-link">Reading</a>
        <a href="/grammar" class="nav-item nav-link">Grammar</a>
        <div class="nav-item dropdown">
          <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Podcast</a>
          <div class="dropdown-menu m-0">
            <a href="/podcast?pod_type=bbc-english" class="dropdown-item">BBC English</a>
            <a href="/podcast?pod_type=youtube" class="dropdown-item">Youtube</a>
          </div>
        </div>
        <!--        <div class="nav-item dropdown">-->
        <!--          <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Resources</a>-->
        <!--          <div class="dropdown-menu m-0">-->
        <!--            <a href="/ebooks" class="dropdown-item">Ebook</a>-->
        <!--&lt;!&ndash;            <a href="/our-feature" class="dropdown-item">Our features</a>&ndash;&gt;-->
        <!--&lt;!&ndash;            <a href="/team-member" class="dropdown-item">Team Members</a>&ndash;&gt;-->
        <!--&lt;!&ndash;            <a href="quote" class="dropdown-item">Free Quote</a>&ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <a href="/posts" class="nav-item nav-link">Posts</a>
        <a href="/contact" class="nav-item nav-link">Contact</a>


        <div class="nav-item dropdown">
          <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Policy</a>
          <div class="dropdown-menu m-0">
            <a href="/private-policy" class="dropdown-item">Private policy</a>
          </div>
        </div>

      </div>
      <button type="button" class="btn text-primary ms-3" data-bs-toggle="modal" data-bs-target="#searchModal"><i
        class="fa fa-search"></i></button>
    </div>
  </nav>
</div>
<!-- Navbar & Carousel End -->
